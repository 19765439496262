@import '../../css/variables';

.new-modal .new-modal-content {
  .modal-contact-mvj {
    overflow: hidden;
    width: 100%;

    .title {
      margin-bottom: 16px;
      font-weight: bold;

      @include media-breakpoint-up(md) {
        margin-top: 16px;
      }
    }

    .subtitle {
      margin-bottom: 8px;
    }

    img {
      max-width: 100%;
    }

    a[href^='tel'] {
      color: $viking-black;
      text-decoration: none;
    }

    .content {
      margin-bottom: 16px;
    }

    .heading-contact-footer {
      text-align: center;
      margin: 16px 0 32px;
      font-weight: bold;

      @include media-breakpoint-down(sm) {
        margin: 12px 0 16px;
      }
    }
  }
}
