@import '../../../css/variables';

.air-preferences-form {
  .passenger-name {
    font-size: 24px;
    padding-bottom: 12px;
  }

  .field-wrapper {
    position: relative;
  }

  .input-text-section .form-group,
  .select-section {
    margin-bottom: 8px;
  }

  .question-mark-tooltip {
    visibility: hidden;
    position: absolute;
    right: -32px;
    top: 10px;

    @include respond-to(false, md) {
      visibility: visible;
      top: 7px;
    }
  }

  &.force-show-tooltips .question-mark-tooltip {
    visibility: visible;
  }

  .frequent-flyer-group {
    .frequent-flyer {
      @include media-breakpoint-up(md) {
        margin: 12px 0 0;
      }
    }

    .btn {
      margin: 0 0 8px;
    }
  }
}
