@import '../../css/variables';

.mvj-header {
  .header {
    $icon-panel-margin: map-get($header-icon-panel-attributes, 'margin');

    .preheader-container {
      position: fixed;
      z-index: 99;
      width: 100%;
    }

    .header-container {
      position: fixed;
      z-index: 900;

      &.has-pre-header {
        top: 40px;
      }

      .additional-menu .menu-collapse {
        @function calculate-menu-margin($icon-count) {
          $icon-size: map-get($header-icon-panel-attributes, 'size');

          @return $icon-count * $icon-size + $icon-panel-margin;
        }

        @for $i from 1 through 9 {
          .icons-#{$i} & {
            margin-right: calculate-menu-margin($i);
          }
        }
      }
    }

    .right-menu {
      .icon-panel-wrapper {
        float: right;
        padding-top: 0;
      }

      button {
        color: $viking-white;
        font-size: 16px;

        .label {
          margin-right: 4px;
          text-transform: uppercase;
        }
      }
    }
  }

  &.travel-advisor {
    .header-wrapper {
      .header {
        .preheader-container {
          background-color: $viking-red;

          .text-center {
            @include media-breakpoint-down(sm) {
              font-size: 10px;
              flex: 0 0 36%;
              max-width: 36%;
              margin: 0;
            }
          }

          .viking-button.btn-link {
            color: $viking-white;
            border: none;
            box-shadow: none;

            @include media-breakpoint-down(sm) {
              font-size: 10px;
            }

            &:focus,
            &:hover {
              color: $viking-white;
              border: none;
              box-shadow: 0 0 0 3px $viking-red;
            }
          }
        }
      }
    }
  }

  .left-menu-open {
    @include respond-to(false, sm) {
      overflow: hidden;
      position: fixed;

      .header-container {
        height: 100%;
        overflow-y: scroll;
      }
    }
  }
}
