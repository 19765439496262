@import '../../../css/variables';

.payment-methods {
  .payment-method {
    border-bottom: $viking-light-gray-rule;
    padding-top: 32px;

    @include respond-to(false, sm) {
      padding: 16px 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    .btn-secondary-blue {
      width: 221px;

      @include media-breakpoint-down(sm) {
        width: 166px;
      }
    }
  }
}
