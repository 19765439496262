@import '../../css/variables';

.new-modal {
  .new-modal-content {
    .travel-protection-side-content {
      .title {
        text-align: center;
        font-weight: bold;
        padding: 0 10px 15px 10px;

        @include body-copy-font(small);

        @include respond-to(false, md) {
          margin-top: 8px;
          padding: 0 16px 10px 16px;
        }
      }

      .plan-details-link {
        @include body-copy-font(small);

        text-align: center;
        font-weight: bold;

        a {
          color: $viking-black;
          text-decoration: underline;

          &:hover {
            @include link-underline;
          }
        }
      }
    }
  }
}
