@import '../../../css/variables';

.air-modal-side-content {
  .passenger-name {
    font-weight: 700;
  }

  .all-reserved {
    margin-top: 8px;
  }

  .global-message {
    margin-bottom: 8px;
  }
}

.booking-assistance {
  @include body-copy-font(small);

  a {
    color: $viking-black;
    text-decoration: none;
  }

  &:not(.confirmed) {
    text-align: center;
    font-weight: bold;
    padding: 0 10px 10px;

    a {
      text-decoration: none;
    }
  }

  &.confirmed {
    font-weight: bold;
    margin: 8px 0;
    color: $viking-red;

    a {
      text-decoration: none;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 8px;

    a {
      text-decoration: none;
    }
  }
}
