@import '../../css/variables';

.new-modal .new-modal-content {
  .extension-side-content {
    .validation-error-text {
      text-align: center;
      margin-top: 8px;

      @include body-copy-font(small);
    }

    .booking-assistance {
      @include body-copy-font(small);

      &:not(.confirmed) {
        text-align: center;
        font-weight: bold;
        padding: 0 10px 10px;
      }

      &.confirmed {
        font-weight: bold;
        margin: 8px 0;
        color: $viking-red;
      }

      a {
        color: $viking-black;
        text-decoration: none;
      }

      @include media-breakpoint-down(md) {
        margin-top: 8px;
      }
    }

    .subtext {
      @include body-copy-font(small);

      text-align: center;
      font-weight: bold;
    }
  }
}
