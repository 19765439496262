@import '../../css/variables';

.help-page {
  padding-top: 16px;

  .under-toc {
    margin: 32px auto 0;
    padding: 24px 0;
    border-top: 1px solid $viking-gray;
    box-sizing: border-box;
  }

  .under-qs {
    margin: 24px auto 0;
  }

  .faqs {
    .table-of-contents .list-group-item {
      z-index: 10;
    }

    .answer {
      ul {
        margin-bottom: 16px;
      }
    }
  }
}
