@import '../../css/variables';

.guest-selector-form {
  padding-left: 0;
  padding-right: 0;

  .guest-selector-section-header-wrapper {
    justify-content: center;

    .guest-selector-section-header {
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 8px;
      margin-top: 16px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
        margin-top: 32px;
      }
    }
  }

  .form-section-fields {
    justify-content: center;

    .guest-selector-status {
      color: $viking-black;
      font-weight: bold;

      &.pending {
        color: $viking-red;
        font-weight: normal;
      }
    }

    .radio-section {
      margin-bottom: 8px;

      @include body-copy-font(small);

      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;

        @include body-copy-font(medium);
      }

      .guest-selector-status,
      .guest-selector-name {
        @include body-copy-font(small);

        @include media-breakpoint-up(lg) {
          @include body-copy-font(medium);
        }
      }
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}
