@import '../../css/variables';

.photo-upload-modal {
  align-items: center;

  .title {
    @media (max-width: 768px) {
      margin-top: -27px;
    }

    @media (min-width: 768px) {
      margin-top: -33px;
    }
  }

  .photo-upload-form {
    .photo-upload-fields {
      justify-content: center;
      margin-bottom: 8px;
      text-align: center;

      .title {
        font-weight: bold;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 8px;
      }

      .card {
        border: 0;
      }

      .card-text {
        text-align: left;
        font-size: 16px;

        ul {
          padding-inline-start: 24px;
        }
      }

      .card-body {
        padding: 1.25rem 0;
      }
    }

    .photo-upload {
      justify-content: center;
      text-align: center;

      .image-format-error {
        margin-top: 30px;
        font-size: 14px;
        color: $viking-red;
      }

      .confirmation-text1 {
        margin-top: 48px;
      }

      .confirmation-text2 {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
          margin-bottom: 32px;
        }
      }

      .col-md-10 {
        .App {
          .container-icon {
            margin: 48px 0 40px 0;

            .icon {
              width: 79px;
              height: 118px;
            }
          }
        }
      }

      .subtitle {
        margin-bottom: 24px;
      }
    }

    .photo-upload-crop {
      justify-content: center;
      text-align: center;

      .title {
        @include media-breakpoint-down(sm) {
          font-size: 22px;
          margin-bottom: 8px;
        }
      }

      .subtitle {
        height: 520px;

        @include media-breakpoint-down(sm) {
          font-size: $small-body-copy;
          height: 294px;
        }
      }
    }

    .photo-upload-success {
      justify-content: center;
      text-align: center;

      .subtitle {
        margin-bottom: 36px;

        ul {
          text-align: left;
        }
      }
    }

    .form-actions-wrapper .form-actions .buttons {
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        margin-bottom: 8px;
      }

      .btn {
        height: 40px;

        @include media-breakpoint-up(md) {
          width: 254px;
        }
      }

      .btn-icon {
        text-decoration: underline;
        text-underline-offset: 5px;

        .icon-prev-bold {
          display: none;
        }
      }
    }
  }

  .separator {
    border-top: 2px solid $viking-medium-gray;
  }

  input[type='file'] {
    display: none;
  }

  .custom-file-upload {
    display: inline-block;
    padding: 13px 25px;
    cursor: pointer;
    border: 0;
    border-radius: 0;
    background-color: #0f3055;
    color: #fff;
  }

  .crop-container {
    position: absolute;
    top: 40px;
    left: -80px;
    right: 0;
    bottom: 80px;
    width: 670px;
    height: 480px;

    @include media-breakpoint-down(sm) {
      width: calc(100% + 0.02px);
      height: 258px;
      top: 86px;
      left: calc(100% - calc(100% + 0.02px));
    }
  }

  .controls {
    margin-left: -80px;
    margin-right: -80px;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .plus-minus-container {
    font-size: 30px;
    width: 700px;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
      width: calc(100% + 30px);
      left: calc(100% - calc(100% + 0.02px));
      font-size: 40px;
      line-height: 40px;
    }

    .minus {
      text-align: left;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        margin-left: -79px;
      }

      &.disabled {
        color: #888;
        cursor: not-allowed;
      }
    }

    .plus {
      text-align: right;
      cursor: pointer;

      &.disabled {
        color: #888;
        cursor: not-allowed;
      }
    }
  }

  .slider {
    padding: 22px 0;
  }
}
