@import 'node_modules/@viking-eng/css/src/variables';

.passenger-name-wrapper {
  border: $viking-thin-gray-rule;
  text-align: center;

  .passenger-name {
    padding: 32px 0;
  }

  .button-wrapper {
    padding: 0 16px;

    .checked-in-alert {
      background-color: #e9f3f0;
      padding: 24px;
      margin-bottom: 24px;

      .checked-in-title {
        color: #056f35;

        @include body-copy-font(medium);

        :first-child {
          margin-right: 8px;
        }
      }
    }

    .or-divider {
      margin-bottom: 24px;

      .or-horizontal-line {
        border: $viking-thin-gray-rule;
        margin: auto;
        width: 40px;
      }

      .or-text {
        margin: 0 24px;
        font-size: $small-body-copy;
      }
    }

    .email-title {
      padding-bottom: 24px;

      @include header-font(4);
    }

    button {
      &.btn-secondary-blue {
        width: 100%;
        margin-bottom: 24px;
      }

      &.with-disabled-msg {
        margin-bottom: 12px;
      }
    }

    .email-boarding-pass {
      justify-content: space-between;

      .form-submit-wrapper {
        padding-left: 8px;

        @include media-breakpoint-up(md) {
          padding-left: 16px;
        }
      }
    }

    .mxp-error {
      color: $viking-red;
      font-size: $small-body-copy;
      margin-bottom: 8px;
    }

    .gif-incomplete-error {
      .alert-with-link {
        color: $viking-red;
        font-size: $small-body-copy;
        margin-bottom: 8px;

        a {
          text-decoration: underline;
        }
      }
    }

    .combo-error {
      .alert-with-link .btn {
        width: inherit;
        margin-bottom: 0;
        font-size: inherit;
      }
    }

    .disabled-message {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
}
