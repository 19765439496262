@import '../../../css/variables';

.form-credit-card {
  // .blur-bar {
  //   background-color: black;
  //   position: absolute;
  //   bottom: 0px;
  //   height: 65%;
  //   width: 80%;
  //   margin-left: 25px;
  //   z-index: 10;
  // }

  .expiry-date-error {
    margin-top: -$payment-form-row-margin;
    margin-bottom: $payment-form-row-margin;
  }

  .token-ex-row {
    position: relative;

    .card-container {
      position: absolute;
      top: -2px;
      right: 6px;

      @include media-breakpoint-down(lg) {
        top: 0;
      }

      @include media-breakpoint-down(sm) {
        top: 2px;
      }

      &.for-edit {
        top: -5px;
      }

      .icon-unknown-card,
      .icon-visa,
      .icon-mastercard,
      .icon-discover,
      .icon-american-express {
        margin-left: 6px;
        font-size: 50px;

        @include media-breakpoint-up(md) {
          font-size: 54px;
          margin-bottom: -4px;
        }
      }
    }
  }
}
