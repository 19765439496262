@import '../../css/variables';

$section-margin-desktop: 32px;
$section-margin-mobile: 16px;

$box-width_mobile: 115px;
$box_height_mobile: 88px;
$box_height_mobile_map: 49px;

@mixin boxes-class-child-content-dimensions {
  width: $box-width_mobile;
  height: $box_height_mobile;
}

@mixin boxes-class-map-content-dimensions {
  width: $box-width_mobile;
  height: $box_height_mobile_map;
}

.booking-details-section {
  @include respond-to(md, false) {
    margin-top: $section-margin-desktop;
  }

  @include respond-to(false, md) {
    margin-top: $section-margin-mobile;
  }

  .cruise-name {
    @include respond-to(md, false) {
      display: inline-block;
    }

    @include respond-to(false, md) {
      display: block;
      text-align: center;
    }
  }

  .boxes {
    $box-width: 144px;
    $box-height: 110px;

    @include media-breakpoint-down(sm) {
      margin-top: $section-margin-mobile;
      text-align: center;
      position: relative;
      overflow: hidden;
      padding: 0;

      .box {
        display: inline-block;
        padding: 0;
        margin-right: 16px;

        &.right {
          margin-right: 0;
        }
      }
    }

    .days-to-go {
      background-color: $viking-light-gray;
      display: flex;
      height: $box-height;
      width: $box-width;

      @include media-breakpoint-down(sm) {
        @include boxes-class-child-content-dimensions;
      }

      .days {
        font-size: 40px;
        line-height: 48px;

        @include media-breakpoint-down(sm) {
          line-height: 40px;
        }
      }

      .label {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .map {
      position: relative;
      cursor: pointer;
      width: $box-width;
      padding: 0;

      @include respond-to(md, false) {
        margin-top: 24px;
      }

      @include media-breakpoint-down(sm) {
        margin-left: 0;
        width: 133px;

        .image-container:not(.ratio-original) .img-error {
          background: $viking-light-gray center center no-repeat;
        }
      }

      .map-thumbnail {
        height: $box-height;
        width: $box-width;

        @include media-breakpoint-down(sm) {
          width: $box-width_mobile;
          height: 87px;
        }
      }

      .btn-details {
        background-color: rgba(255, 255, 255, 0.6);
        line-height: 1;
        padding: 8px 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;

        .btn {
          font-size: 14px;
        }

        @include media-breakpoint-down(sm) {
          padding-right: 8px;
        }
      }
    }
  }

  .medium-body-copy div {
    width: fit-content;
  }

  .booking-details {
    @include respond-to(md, false) {
      margin-top: $section-margin-desktop;
    }

    @include media-breakpoint-down(sm) {
      margin-top: $section-margin-mobile;
    }

    .container {
      padding: 0;

      .row {
        &:not(:last-child) {
          @include respond-to(sm, false) {
            margin-bottom: 16px;
          }

          @include respond-to(false, sm) {
            margin-bottom: 24px;
          }
        }

        .heading {
          color: $viking-dark-gray;

          @include respond-to(sm, false) {
            line-height: 21px;
          }
        }

        .detail {
          .btn-link {
            @include body-copy-font(medium);
          }

          > div {
            display: flex;
          }
        }
      }
    }
  }

  .cta-wrapper {
    $icon-margin: 8px;
    $icon-size: 16px;

    .booking-link-text {
      display: inline-flex;

      button {
        display: inline-block;
        white-space: normal;
        word-wrap: break-word;

        .booking-link-image {
          display: inline-flex;
          cursor: pointer;

          .container-icon {
            font-size: $icon-size;
            margin-right: 4px;
          }
        }

        @include body-copy-font(medium);
      }
    }
  }
}

.new-modal-content {
  background-clip: border-box;

  .travel-protection-side-content {
    .title {
      text-align: center;
      font-weight: bold;
      padding: 0 10px 15px 10px;

      @include body-copy-font(small);

      @include respond-to(false, md) {
        margin-top: 8px;
        padding: 0 16px 10px 16px;
      }
    }

    .plan-details-link {
      @include body-copy-font(small);

      text-align: center;
      font-weight: bold;

      a {
        color: #000;
        text-decoration: underline;
      }
    }
  }

  .stateroom-modal {
    .btn-secondary-blue {
      @include body-copy-font(medium);

      @include media-breakpoint-down(sm) {
        @include body-copy-font(small);

        float: right;
      }
    }
  }
}

.new-modal .new-modal-content {
  display: block;

  .picture-modal {
    @include respond-to(md, false) {
      margin-bottom: -24px;
    }

    @include respond-to(false, md) {
      margin-bottom: -16px;
    }
  }
}

#bookingMapModal {
  div.img-error {
    height: 300px;
  }
}
