@import '../../css/variables';

.dining-confirmation {
  .validation-message-text {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 26px;
    }

    .table-for-2-sub-head {
      font-size: $medium-body-copy;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .remaining-reservation {
    font-size: $medium-body-copy;
    font-weight: 700;
    line-height: 22px;

    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
      line-height: 20px;
    }
  }

  .booking-detail .connector {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .passengers-details {
    margin-top: 16px;
    margin-bottom: 8px;

    @include media-breakpoint-down(sm) {
      margin-top: 12px;
    }
  }

  .passenger-name {
    font-size: $large-body-copy;
    font-weight: 700;
    line-height: 24px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      font-size: $medium-body-copy;
      line-height: 22px;
    }
  }

  .booking-details {
    font-size: $small-body-copy;
    line-height: 20px;
  }
}

.add-to-cart-modal-confirmation {
  .status-message {
    @include body-copy-font(small);

    &.validation-error-text {
      color: $viking-red;
      font-weight: 400;

      a {
        color: $viking-red;
        text-decoration: underline;
      }
    }

    &.validation-message-text {
      font-weight: bold;
    }
  }

  .actions {
    margin-top: 16px;

    .btn {
      margin-bottom: 8px;
      width: 100%;
    }

    .validation-message-text {
      color: $viking-black;
      font-weight: bold;
    }
  }

  .booking-details {
    .button-with-alert {
      margin: 16px 0 8px;
      width: 100%;
    }

    .passenger-name {
      font-weight: bold;
      text-transform: uppercase;
    }

    .edit-link {
      font-size: 16px;
      margin: 8px 0 24px;
    }

    .booking-details {
      margin-bottom: 8px;
    }

    .loading-spinner {
      margin-top: 16px;
    }
  }
}

.dining-reservation-help {
  font-size: $small-body-copy;
}

.dining-modal-container {
  .dining-confirmation {
    .booking-details {
      @include media-breakpoint-down(sm) {
        margin-bottom: 24px;
      }
    }
  }

  .booking-detail {
    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block;
    }
  }

  .booking-detail-break {
    height: 0;
  }
}
