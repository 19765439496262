@import '../../../css/variables';

.calendar {
  .day-title {
    @include page-header-margins(margin-bottom);
  }

  .upsell-bar-wrapper {
    // TODO: Update to match wires on all breakpoints
    margin-bottom: 32px;
  }

  .column-wrap {
    display: flex;
    z-index: 1;
  }

  .header {
    font-size: $small-body-copy;
    font-weight: bold;

    .year {
      @include respond-to(false, md) {
        display: none;
      }
    }
  }

  .event-wrapper {
    position: absolute;
    left: 0;
    right: 0;

    .btn:not(.btn-link),
    .btn-content {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }

  .column {
    width: 50%;
    top: 0;
    bottom: 0;
    position: relative;

    .h7 {
      text-align: center;
    }

    &.column-full {
      position: absolute;
      top: 0;
      bottom: 0;
      width: auto;
      margin: 0 15px;
      left: 0;
      right: 0;
    }

    .column-content {
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
    }

    &:not(.column-full):nth-child(odd) {
      .column-content {
        margin-right: 15px;
      }
    }

    &:not(.column-full):nth-child(even) {
      .column-content {
        margin-left: 15px;
      }
    }
  }

  .legend {
    font-size: $small-body-copy;
    font-weight: 700;

    .onboard-event,
    .shore-event {
      width: 50%;
      display: inline-block;
      position: relative;

      @include respond-to(sm, false) {
        width: 190px;
      }

      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 8px;
        vertical-align: middle;
      }
    }

    .onboard-event::before {
      background-color: $viking-light-blue;
    }

    .shore-event::before {
      background-color: $viking-pink;
    }
  }

  .passenger-wrap {
    display: flex;
  }
}

.time-conflict-modal-spinner {
  width: 100%;
  text-align: center;
  font-size: $large-body-copy;
}
