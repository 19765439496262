@import '../../css/variables';

.seatmap-divider {
  border-top: 1px solid $viking-gray;
  display: flex;
  justify-content: space-between;
  margin-right: -20px;
  margin-left: -20px;
  margin-top: 236px;

  @include media-breakpoint-down(sm) {
    margin-right: -12px;
    margin-left: -12px;
    margin-top: 210px;
  }
}

.abe-air-seats.seats-modal {
  margin-top: -236px;
  max-width: none;
  padding: 0 4px;

  @include media-breakpoint-down(sm) {
    margin-top: -210px;
  }

  .spinner-container {
    margin-top: 58px;
  }

  .seat-cabin {
    margin-top: 0;
  }

  .legend-title {
    margin-bottom: 24px;
    margin-top: 24px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
      margin-top: 16px;
    }

    .btn {
      margin: 0;
      padding: 0;
      font-weight: 700;
      color: $viking-black;

      @include media-breakpoint-down(sm) {
        font-size: $small-body-copy;
      }
    }

    .container-icon {
      margin-right: 10px;
    }
  }

  .legend-box {
    width: 280px;
    height: 60px;
    margin: 0 auto 24px;

    &.tall-box {
      @include media-breakpoint-down(sm) {
        height: 90px;
      }
    }

    .legend-icon {
      height: 24px;
      width: 24px;
      margin: 0 8px 12px 0;

      &.exit-icon {
        width: 4px;
        height: 20px;
        margin-left: 8px;
        margin-right: 20px;

        &.exit-right {
          margin-left: 25px;
        }
      }
    }

    .legend-label {
      float: left;
      font-size: $small-body-copy;
      margin-top: 2px;
    }
  }

  .flight-details-select {
    span {
      display: inline-block;

      &.hide-mobile {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    .width-100 {
      line-height: 22px;
    }

    .selected-right-icon {
      float: right;
      background-color: $viking-navy-blue;
      width: 36px;
      margin-right: -16px;
      margin-top: -16px;
      height: 80px;
      margin-left: 16px;

      @include media-breakpoint-down(sm) {
        height: 64px;
        width: 32px;
      }

      .icon {
        color: $viking-white;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        margin-top: 29px;
        font-size: $medium-body-copy;

        @include media-breakpoint-down(sm) {
          margin-top: 25px;
        }
      }
    }

    .width-100.not-bold {
      font-weight: 400;
      float: left;
      min-width: 400px;
      margin-top: 4px;

      @include media-breakpoint-down(sm) {
        min-width: 200px;
        margin-top: 0;
      }
    }

    .details-margin {
      margin-left: 32px;

      @include media-breakpoint-down(sm) {
        margin-left: 20px;
      }
    }
  }

  .seat-map-container {
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }

    .btn-strong-border {
      margin: 0 auto 28px;
      width: 290px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
        width: 260px;
      }
    }

    .upper {
      margin-top: 48px;
    }

    button {
      .container-icon {
        .icon {
          margin-left: 10px;
          margin-top: -4px;
        }
      }
    }
  }

  .select-section {
    z-index: 1000;
    margin-bottom: 28px;
    padding-left: 36px;
    padding-right: 36px;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
      max-height: 1000px;
    }

    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
      margin-bottom: 24px;
      padding: 0;
    }

    .details-first-row {
      margin-bottom: 4px;
      margin-top: -2px;
    }

    .select-custom-control {
      .select__option {
        height: 84px;
        border: 1px solid $viking-gray;
        padding: 16px;
        border-bottom: none;

        @include media-breakpoint-down(sm) {
          height: 68px;
        }
      }

      .mobile-flights {
        width: 150px;
        text-align: left;
      }

      .select__menu {
        border: none;
        border-bottom: 1px solid $viking-gray;
        background-color: $viking-white;
        max-height: 1000px;

        .mobile-flights {
          margin-top: -6px;
        }

        .filght-number {
          @include media-breakpoint-down(sm) {
            margin-top: -6px;
          }
        }
      }

      .select__control--is-focused {
        box-shadow: none;
        border: none;
      }

      .select__control {
        height: auto;
        padding: 16px;
        box-shadow: none;
        border: 2px solid $viking-navy-blue;
        background: transparent;

        @include media-breakpoint-down(sm) {
          padding: 14px 16px;
        }
      }

      .select__menu-list {
        padding: 0;
        margin-top: -84px;
        z-index: 10000;
        background-color: $viking-white;
        max-height: 1300px;

        ::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }

        @include media-breakpoint-down(sm) {
          margin-top: -68px;
        }
      }

      .select__value-container,
      .select-custom-control {
        height: 48px;
        padding: 0;
        margin-right: -8px;

        @include media-breakpoint-down(sm) {
          height: 36px;
        }
      }

      .select__option--is-selected {
        background-color: transparent;
        border: 2px solid $viking-navy-blue;
      }

      .select__single-value {
        width: 100%;

        .selected-right-icon {
          display: none;
        }
      }

      .select__indicators {
        background-color: $viking-navy-blue;
        margin: -16px -16px -16px 16px;
        padding: 0 10px;
        z-index: -5;

        @include media-breakpoint-down(sm) {
          padding: 0 8px;
        }
      }

      .select__dropdown-indicator {
        color: $viking-white;
        width: 16px;
        height: 16px;
        padding: 0;

        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  h3 {
    margin-top: 18px;
    margin-bottom: 16px;

    @include media-breakpoint-down(sm) {
      font-size: 22px;
      margin-top: 12px;
      margin-bottom: 8px;
    }
  }

  .empty-seat-map {
    margin-top: 24px;

    @include media-breakpoint-down(sm) {
      margin: 0 auto;
      margin-top: 16px;
      width: 290px;
    }
  }

  .sub-title {
    font-size: $medium-body-copy;
    margin-bottom: 32px;

    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
      margin-bottom: 28px;
      line-height: 18px;
    }
  }
}
