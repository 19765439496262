@import '../../../css/variables';

.calendar-line {
  /* !! IMPORTANT!! Here be :dragon:!
   * if you change the `height` value below, you MUST also
   * change the value of `CALENDAR_ITEM_PIXELS_PER_HOUR` variable in ./CalendarLine.jsx!
   * These values must be kept in sync, otherwise the positioning
   * of events on the calendar will be incorrect. :scream:
   */
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .calendar-line-inner {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .meta {
    display: flex;
    flex-direction: column;
    font-size: $small-body-copy;
    color: $viking-dark-gray;
    position: relative;
  }

  .port {
    @include respond-to(sm, xl) {
      min-width: 76px;
    }

    @include respond-to(false, sm) {
      min-width: 45px;
    }
  }

  .time {
    white-space: nowrap;

    .minutes {
      @include respond-to(false, md) {
        display: none;
      }
    }

    .port {
      float: right;
    }
  }

  hr {
    border-top: $viking-medium-gray-rule;
    margin: -1px 0 0;
  }

  &.thick {
    hr {
      border-top: $viking-thick-black-rule;
    }

    .meta {
      font-weight: bold;
      color: $viking-black;
    }
  }
}
