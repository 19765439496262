@import '../../css/variables';

@media print {
  .mvj-modal {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color: black;
    line-height: 22px;
  }
}
