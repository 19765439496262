@import '../../css/variables';

.modal-footer-mvj {
  overflow: hidden;
  clear: both;

  .modal-print {
    text-transform: uppercase;
    font-size: 0.7em;
    color: black !important;
    text-decoration: underline !important;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-weight: bold;

    @media print {
      display: none;
    }
  }

  .heading-modal-footer {
    text-align: center;
    margin: 16px 0;
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      margin: 12px 0 8px;
    }
  }

  iframe {
    width: 100%;
    height: 70vh;
  }
}

#contact-modal {
  iframe {
    height: 40vh;
    min-height: 300px;
  }
}

.information-links button {
  color: $viking-white !important;
  font-size: $small-body-copy !important;
}

.call-viking {
  background-color: $viking-royal-blue;
  color: $viking-white;
  text-align: center;

  a {
    color: $viking-white;
  }
}
