@import '../../css/variables';

.home-page {
  padding-top: 16px;

  .notifications {
    margin-bottom: 16px;
  }

  .faq-container {
    .viking-button.btn-primary {
      font-size: $large-body-copy;
      padding: 13px 25px;
      height: 50px;

      @include media-breakpoint-down(sm) {
        font-size: $small-body-copy;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .faq-text {
    margin-top: 24px;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      margin-top: 16px;
    }
  }

  @include respond-to(lg, false) {
    padding-top: 24px;
  }
}
