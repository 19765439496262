@import '../../css/variables';

#PaymentChallengeModal {
  // Required as iframe will end up having horizontal scrolling otherwise
  .timer-header {
    padding-left: 20px;
    background-color: $viking-medium-light-gray;

    .count-down {
      font-weight: bold;
    }
  }

  .new-modal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .icon-wrapper {
      padding-top: 70px;
      text-align: center;

      .container-icon {
        font-size: 87px;
      }
    }

    .redirect-message {
      padding-top: 40px;
      text-align: center;
    }

    .button-wrapper {
      text-align: center;
    }
  }
}
