@import '../../../css/variables';

.tokenex-wrapper {
  width: 100%;
  position: relative;

  .placeholder-wrapper {
    display: none;

    &.iframe-loaded {
      display: block;
    }

    .tokenex-placeholder {
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px 15px;
      font-size: 12px;
      font-weight: normal;
      color: $viking-dark-gray;
      transform: translate3d(0, -20%, 0);
      pointer-events: none;

      @include respond-to(false, md) {
        font-size: 10px;
        padding: 8px 15px;
      }

      &.error {
        color: $viking-red;
      }
    }
  }

  .field-wrapper {
    .validation-error-text {
      color: $viking-red;
      font-size: 13px;
      line-height: 19px;
    }
  }

  .container-icon {
    font-size: 50px;
  }

  .iframe-wrapper {
    position: relative;
    height: 50px;

    @include media-breakpoint-down(lg) {
      height: 56px;
    }

    @include media-breakpoint-down(md) {
      height: 55px;
    }

    .iframe {
      $box-shadow-size: 3px;

      position: absolute;
      left: -$box-shadow-size;
      top: -$box-shadow-size;
      width: calc(100% + #{2 * $box-shadow-size});
      height: calc(100% + #{2 * $box-shadow-size});

      @include media-breakpoint-down(lg) {
        height: calc(100% + #{2 * ($box-shadow-size + 2)});
      }
    }

    .input-text-section {
      .form-control {
        @include respond-to(false, md) {
          height: 50px;
        }
      }

      .form-control-placeholder {
        display: none;
      }

      &.iframe-loaded {
        display: block;
      }
    }
  }

  .iframe-wrapper-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
