@import '../../css/variables';

.login-subheader {
  @extend %preauth-subheader;

  text-align: center;
  position: relative;

  + *:not(.login-header) {
    margin-top: 32px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;
  }

  .back {
    position: absolute;
    width: 32px;
    height: 32px;
    left: -8px;
    font-size: 16px;
  }
}
