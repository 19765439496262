@import 'node_modules/@viking-eng/css/src/variables';

.air-nav-container {
  position: sticky;
  bottom: 0;
  right: 0;
  display: flex;
  padding: 8px 16px;
  width: 100%;
  height: 66px;
  background-color: $viking-light-gray;
  box-shadow: 0 4px 11px 3px rgba(0, 0, 0, 0.32);
  justify-content: center;
  z-index: 99;

  @include media-breakpoint-down(md) {
    height: 56px;
  }

  .viking-button.btn-link {
    font-size: $large-body-copy;

    @include media-breakpoint-down(md) {
      font-size: $small-body-copy;
    }
  }

  .sec-btn {
    text-align: right !important;
    margin-right: 16px;
    width: 160px;

    @include media-breakpoint-down(md) {
      width: 145px;
    }

    @include media-breakpoint-down(sm) {
      text-align: center !important;
      margin-right: 0;
    }
  }

  .prim-btn {
    margin-left: 16px;
    width: 162px;

    @include media-breakpoint-down(md) {
      width: 145px;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }
}

.exit-seat-modal {
  ul {
    text-align: left;
    margin-top: 20px;
  }
}
