@import '../../../css/variables';

.field-wrapper {
  .description {
    margin: 4px 0 16px;
    font-size: $small-body-copy;
  }

  .image {
    margin-top: 8px;
  }
}
