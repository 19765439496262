@import '../../css/variables';

.preauth-interstitial {
  .preauth-form-content-wrapper {
    max-width: 290px;
    margin: auto;

    @include media-breakpoint-up(md) {
      max-width: 440px;
    }
  }

  .interstitial-actions {
    max-width: 408px;
    margin: 0 auto 60px;

    @include media-breakpoint-only(md) {
      margin-bottom: 340px;
    }

    @include media-breakpoint-only(lg) {
      margin-bottom: 197px;
    }

    @include media-breakpoint-only(xl) {
      margin-bottom: 372px;
    }
  }

  .header-wrapper {
    margin-bottom: 32px;
    text-align: center;

    .subheader {
      @extend %preauth-subheader;

      margin: 0;
    }
  }

  .content {
    margin-bottom: 40px;
    text-align: center;

    button {
      font-size: $medium-body-copy;
    }
  }

  &.preauth-page-wrapper .btn {
    margin: auto;
  }

  .forgot-pass {
    max-width: 408px;
  }
}
