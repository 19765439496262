@import 'node_modules/@viking-eng/css/src/variables';

.comfort-check-in-page-wrapper {
  .loading-spinner {
    padding-top: 52px;
  }

  .section-header-wrapper {
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }
  }

  .content-item {
    padding: 0 16px;
  }

  .viking-button {
    &.btn-link {
      &.alert-button {
        font-weight: normal;
        color: $viking-blue;
        float: left;
        text-decoration: none;
        margin-top: 8px;
        margin-bottom: 36px;
        padding-bottom: 0;
        font-size: 14px;
      }

      @include media-breakpoint-down(md) {
        text-underline-position: auto;
      }
    }

    &.btn-link:focus {
      box-shadow: none;
    }
  }

  .alert-with-link .btn {
    width: inherit;
    margin-bottom: 0;
    font-size: inherit;
  }

  .form-actions-wrapper {
    .form-actions:not(.form-actions-stacked) {
      .buttons {
        .btn {
          @include media-breakpoint-up(sm) {
            min-width: 160px;
            width: auto;
          }
        }
      }
    }
  }

  .card {
    &.guest-card-no-image .image-container {
      background-color: $viking-light-gray;

      > img {
        max-width: 50%;
        margin: auto;
      }
    }

    .photo-upload-image {
      .image-container:not(.ratio-original).ratio-5x4 {
        height: 100%;
      }

      .upload-image {
        height: auto;

        @include media-breakpoint-down(md) {
          height: 320px;
          width: auto;
        }
      }
    }

    .photo-button-alert {
      @include media-breakpoint-down(sm) {
        margin: 0;
      }

      .button-with-alert {
        @include media-breakpoint-down(sm) {
          margin-left: 0;
          max-width: 100%;
        }
      }
    }
  }
}
