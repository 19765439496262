@import '../../css/variables';

.shorex-page {
  .shorex-heading {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 40px;

    @include respond-to(false, md) {
      font-size: 20px;
    }
  }

  .alert-with-link {
    color: $viking-black;
    font-weight: bold;
    margin-top: 0;

    .btn {
      font-weight: bold;
    }
  }

  .no-excursions-message {
    @include respond-to(md, false) {
      margin-top: 104px;
    }

    @include respond-to(false, md) {
      margin-top: 64px;
    }
  }

  .viking-button.card.fixed-width {
    min-height: 358px;
  }

  .fixed-width.viking-button .card-title {
    margin-bottom: 8px;
  }
}
