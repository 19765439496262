@import '../../css/variables';

.page-message {
  color: $viking-dark-gray;
  margin: 0 auto;
  max-width: 575px;
  font-size: $medium-body-copy;

  @include media-breakpoint-down(md) {
    font-size: $small-body-copy;
  }
}
