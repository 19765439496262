@import '../../../css/variables';

.calendar-card-list {
  .itinerary-card:not(:last-child) {
    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }
  }

  a {
    margin-right: 4px;
    margin-left: 4px;
  }
}
