@import '../../../css/variables';

.payments-checkout {
  .step {
    border-bottom: $viking-light-gray-rule;
    padding: 32px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom-width: 0;
    }
  }

  .step-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .select-account {
    margin-bottom: 32px;
  }

  .sidebar {
    .payment-failed {
      color: $viking-red;

      @include body-copy-font(medium);

      padding-top: 8px;
    }
  }

  .form-ach,
  .form-credit-card {
    @include payment-form;

    .cvv-tip {
      @include media-breakpoint-down(md) {
        font-size: $small-body-copy;
        line-height: 20px;
      }

      &.half-width {
        @include media-breakpoint-up(md) {
          margin-right: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        font-size: $small-body-copy;
      }
    }
  }

  .noPaymentRequired {
    color: $viking-dark-gray;
    font-size: $medium-body-copy;

    p:nth-child(1) {
      font-weight: bold;
    }
  }

  .no-payment-required-title {
    font-weight: 700;
    font-size: $large-body-copy;

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
    }
  }

  .no-payment-required-message {
    font-size: $medium-body-copy;

    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
    }
  }

  .saveDescription {
    color: $viking-black;
    font-size: $medium-body-copy;
    padding-left: 40px;

    @include respond-to(false, md) {
      padding-left: 38px;
      font-size: $small-body-copy;
      line-height: 20px;
    }
  }

  .cc-expired {
    color: $viking-red;
    padding-left: 5px;
  }

  .secure-checkout {
    @include body-copy-font(small);

    color: $viking-dark-gray;
    font-weight: 700;
    margin-top: 8px;
    width: 100%;

    .container-icon {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .prev-link {
    @include media-breakpoint-down(sm) {
      margin-top: 32px;
    }
  }

  .sm-submit-btn {
    width: 100%;
    margin-top: 12px;
  }

  .terms-conditions-link {
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 32px;
    }
  }

  .payment-process-alert {
    margin-bottom: 20px;
  }

  .disclaimer-message {
    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
    }

    margin-top: 10px;

    a {
      text-decoration: underline;
    }
  }
}
