@import '../../../css/variables';

.search-bar {
  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;
  }

  .search-row {
    justify-content: center;
  }

  .search-field {
    max-width: 731px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .close-wrapper {
    .icon-close {
      font-size: 22px;
    }

    position: absolute;
    right: 0;
    top: 6px;

    @include media-breakpoint-down(sm) {
      top: 2px;
    }
  }

  .form-submit {
    background-color: $viking-red;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      height: 40px;
      width: 40px;
      line-height: 40px;
    }

    .icon {
      font-size: 18px;
      color: $viking-white;
    }
  }
}

@media print {
  .search-bar {
    display: none;
  }
}
