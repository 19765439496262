@import '../../css/variables';

.app-modal {
  .store-button {
    padding-top: 16px;

    &:first-child {
      padding-right: 16px;
    }

    border-bottom: none;
  }

  .store-img {
    height: 50px;

    @include respond-to(false, md) {
      height: 40px;
    }
  }

  .heading {
    width: 100%;

    .title {
      padding-bottom: 8px;
    }
  }

  .voyager-app-details {
    @include media-breakpoint-up(md) {
      padding: 16px 24px 8px;
    }

    .app-image {
      padding-right: 32px;
      margin: auto 0;

      @include media-breakpoint-only(sm) {
        padding-right: 24px;
      }

      @include media-breakpoint-only(xs) {
        justify-content: center;
        padding-right: 0;
        display: flex;
      }

      .image-container {
        width: 100%;

        @include media-breakpoint-only(xs) {
          width: 140px;
          height: 140px;
          margin-bottom: 16px;
          padding: 0;
        }
      }
    }

    .buttons {
      padding-top: 24px;

      @include media-breakpoint-down(md) {
        padding-top: 16px;
      }
    }
  }
}
