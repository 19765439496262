@import './variables';
@import 'node_modules/@viking-eng/css/lib/viking';

html,
body,
#root {
  height: 100%;
}

body {
  background-color: $viking-royal-blue;
}

#root {
  display: flex;
  flex-direction: column;
}

.app-content {
  flex-shrink: 0;
  background-color: $viking-white;
  flex-grow: 1;
  padding-bottom: 40px;
  padding-top: 60px;

  > .container {
    height: 100%;
  }

  @media (max-width: 768px) {
    padding-top: 48px;
  }
}

.img-error {
  background-image: url('https://aem-prod-publish.viking.com/content/dam/vikingcruises/en/magnolia-images/site_elements/vikinglogogray.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: $viking-white;
  background-size: auto 22.5%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  img {
    visibility: hidden;
  }
}

.rel-container {
  position: relative;
}

.air-container {
  margin: 0 auto;
}

// Page Transitions
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms linear 500ms;
}

.fade-exit {
  opacity: 1;

  .home-carousel-content {
    visibility: hidden;
  }
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms linear;
}

.fade-exit-done {
  opacity: 0;
}

// Carousel - Slick Slder

.slick-slider {
  max-width: 100vw;
}

fieldset {
  padding: 0;
}

.viking-button.btn-secondary-blue {
  &:not(:disabled):hover {
    background-color: $viking-dark-blue;
  }
}

.heading .title {
  font-family: $open-sans-font-family !important;
}

/*** Start Live Person CSS Styling file ***/

.LPMcontainer {
  width: 1px;
}

.LPMcontainer img {
  max-width: none;
}

.animate-from-right {
  position: fixed;
  right: -533px !important;
  -webkit-transition: right 0.5s ease-out;
  -moz-transition: right 0.5s ease-out;
  -o-transition: right 0.5s ease-out;
  transition: right 0.5s ease-out;
}

.animate-from-right.open {
  right: 0 !important;
}

.proactive-chat {
  bottom: 1px;
  position: fixed;
  right: 0;
}

.proactive-chat .LPMimage {
  position: relative;
  z-index: 10;
  top: -90px;
  left: -35px;
  cursor: pointer;
}

.proactive-chat .LPMcloseButton {
  position: absolute;
  z-index: 100;
  top: -70px;
  left: 245px;
  cursor: pointer;
}

/*** End Live Person CSS Styling file ***/

@media print {
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
}

// UI Kit Overrides
.payment-summary .empty-cart {
  font-size: 17px;
}
