@import 'node_modules/@viking-eng/css/src/_variables.scss';

.mvj-page-header {
  &.has-pre-header {
    padding-top: 40px;
  }
}

.header-wrapper {
  &.preauth-header {
    .container {
      .viking-button.btn-link.preauth-back-button {
        color: $viking-white;
        text-decoration: none;
      }
    }

    .header .header-container {
      position: fixed;
      z-index: 900;
    }
  }
}

@supports (-webkit-backdrop-filter: blur(1px)) {
  button.viking-button.btn-link {
    text-underline-position: auto;
  }
}
