@import '../../css/variables';

.mvj-hero {
  margin-top: -$header-height-desktop;
  margin-bottom: -60px;
  flex-shrink: 0;

  @include respond-to(false, sm) {
    margin-top: -$header-height-mobile;
  }

  .image-container:not(.ratio-original) {
    position: relative;
  }
}
