@import '../../css/variables';

.close-to-sailing-modal {
  .title {
    @include header-font(6);

    margin: auto;

    @include media-breakpoint-up(xl) {
      @include header-font(4);
    }

    span {
      font-weight: 700;
      font-size: $large-body-copy;
    }
  }

  .subtitle {
    font-size: $medium-body-copy;
    margin-bottom: 8px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 16px;
    }
  }

  .horizontal-separator {
    border-top: $viking-thin-gray-rule;
    margin-bottom: 8px;

    @include media-breakpoint-down(xl) {
      margin-bottom: 16px;
    }
  }
}
