@import 'node_modules/@viking-eng/css/src/variables';

// pre-auth validation color
$viking-coral: #ff5a5a;

%preauth-subheader {
  font-size: 0.85rem;
  color: $viking-royal-blue;
  letter-spacing: 5px;
  font-weight: 200;
  text-transform: uppercase;
  margin-bottom: 15px;
}

%preauth-subheader-button {
  cursor: pointer;
  background: transparent;
  border: none;
  width: 30px;
  color: $viking-royal-blue;
}

$payment-form-row-margin: 8px;

@mixin payment-form {
  .row {
    margin-bottom: $payment-form-row-margin;

    .half-width {
      $payment-form-column-margin: 8px;

      margin: 0 $payment-form-column-margin;
      width: calc(50% - #{$payment-form-column-margin});

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .input-text-section {
      width: 100%;

      .form-group {
        margin-bottom: 0;
      }
    }

    .select-section {
      margin-bottom: 0;
    }
  }
}

@mixin modal-with-sidebar {
  $side-margin-large: -24px;
  $side-margin-small: -16px;
  $bottom-margin-large: -24px;
  $bottom-margin-small: $side-margin-small;

  padding-top: 16px;
  width: calc(100% + -2 * #{$side-margin-large});
  margin: 0 $side-margin-large $bottom-margin-large;

  @include respond-to(false, md) {
    width: calc(100% + -2 * #{$side-margin-small});
    margin: 0 $side-margin-small $bottom-margin-small;
  }

  .image {
    padding-top: 24px;

    @include respond-to(false, md) {
      padding-top: 16px;
    }
  }

  .sub-title {
    white-space: pre-wrap;
  }

  .icons {
    padding-top: 16px;
  }

  .description {
    @include body-copy-font(medium);

    @include respond-to(false, md) {
      @include body-copy-font(small);
    }
  }

  .button-with-alert {
    @include respond-to(false, sm) {
      margin-top: 16px;
    }
  }

  .content-outer {
    .content,
    .sidebar {
      padding: 24px;

      @include respond-to(false, sm) {
        padding-bottom: 0;
      }
    }

    .description {
      margin-top: 16px;
    }

    .sidebar {
      padding: 24px 16px;
      background: $viking-light-gray;

      @include respond-to(false, sm) {
        margin: 16px -24px 0;
        padding: 1px 0 24px 0;
      }
    }
  }

  &.mvj-modal {
    .content {
      padding: 0;
    }
  }
}
