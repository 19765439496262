@import '../../../css/variables';

.guest-info-form-container {
  padding-left: 0;
  padding-right: 0;

  .guest-info-section-header-wrapper {
    justify-content: center;

    .guest-info-section-header {
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 16px;
      margin-top: 24px;

      @include respond-to(lg, false) {
        margin-bottom: 24px;
        margin-top: 32px;

        &.with-tooltip {
          margin-bottom: 0;
        }
      }
    }

    .guest-info-section-tooltip {
      margin-bottom: 8px;
      font-weight: 700;
      color: $viking-dark-gray;

      @include body-copy-font(small);

      @include media-breakpoint-up(lg) {
        float: right;
      }
    }
  }

  .form-section-fields {
    justify-content: center;
  }

  .city-zip .half-width {
    width: 100%;

    @include respond-to(md, false) {
      width: 50%;

      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }
    }
  }

  .select-section,
  .input-text-section .form-group {
    margin-bottom: 8px;
  }
}
