@import '../../css/variables';

.ntf-banner {
  .ntf-icon {
    color: $viking-red;
    font-size: 22px;
    margin-right: 16px;

    .container-icon {
      display: flex;
    }

    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }

  .ntf-text {
    a {
      @include link-underline;
    }

    font-size: $medium-body-copy;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
    }
  }
}
