@import '../../css/variables';

.modal-footer-mvj {
  .section {
    margin-bottom: 32px;
  }

  h5 {
    margin-bottom: 16px;
  }
}
