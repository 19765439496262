@import '../../../css/variables';

.abe-air-seats {
  margin: 0 auto;

  .seat-map-seat-container .seat-map-seat .icon-info-italic {
    @include media-breakpoint-down(sm) {
      width: 16px;
      height: 16px;
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: 290px;
  }

  .seats-sticky-details {
    position: sticky;
    right: 0;
    top: 20px;
    height: 100%;
    order: 2;
  }

  .passengers-container {
    margin-bottom: 8px;

    .paid-seat-price {
      float: right;

      @include media-breakpoint-down(sm) {
        font-size: $small-body-copy;
      }
    }

    .paid-seat-text {
      padding-left: 18px;

      @include media-breakpoint-down(sm) {
        padding-left: 16px;
        font-size: $small-body-copy;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 4px;
    }

    .passenger-name-container {
      width: calc(100% - 114px);
      font-size: $medium-body-copy;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-down(sm) {
        font-size: $small-body-copy;
      }
    }

    .passenger-seat-container {
      font-size: $medium-body-copy;
      padding-left: 0;
      max-width: 114px;
      text-align: right;
      font-weight: 700;

      &.unassigned {
        color: $viking-dark-gray;
        font-weight: 400;
      }

      @include media-breakpoint-down(sm) {
        font-size: $small-body-copy;
      }
    }
  }

  .from-to {
    margin-bottom: 8px;
    font-weight: 700;

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;
      font-size: $medium-body-copy;
    }
  }

  .flight-in-summary {
    margin: 0 auto 20px;
    border: none;

    @include media-breakpoint-down(sm) {
      margin-bottom: 36px;
    }
  }

  .seats-sticky-details,
  .flight-in-summary {
    div {
      &:focus {
        box-shadow: $viking-box-shadow-highlight-blue;
      }
    }
  }

  .select-change-seat {
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
      font-size: $small-body-copy;
    }
  }

  .flight-cabin-class {
    font-weight: 700;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    @include media-breakpoint-down(md) {
      font-size: $small-body-copy;
    }
  }

  .legend-box {
    margin-top: 32px;

    @include media-breakpoint-down(md) {
      margin-top: 16px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 12px;
    }

    &.md-center {
      @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto;
        width: 250px;
      }
    }

    .legend-header {
      font-weight: 700;
      margin-bottom: 12px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 8px;
      }
    }

    .legend-icon {
      width: 32px;
      height: 32px;
      margin: 0 12px 8px 0;
      border-radius: 4px;
      display: flex;
      float: left;
      justify-content: center;
      align-items: center;
      font-size: $large-body-copy;
      font-weight: 700;

      @include media-breakpoint-down(sm) {
        font-size: $medium-body-copy;
      }

      @include media-breakpoint-down(sm) {
        width: 20px;
        height: 20px;
      }

      &.available-icon {
        background-color: $viking-navy-blue;
      }

      &.paid-seat-icon {
        background-color: $viking-sky-blue;
      }

      &.selected-icon {
        background-color: $viking-dark-green;
        color: $viking-white;
      }

      &.un-available-icon {
        background-color: $viking-gray;
        color: $viking-dark-gray;
      }

      &.exit-icon {
        width: 4px;
        height: 20px;
        background-color: $viking-red;
        margin-left: 8px;
        margin-right: 20px;
      }
    }

    .no-click:last-child {
      .legend-icon {
        margin-bottom: 0;
      }
    }

    .legend-label {
      display: inline-block;
      margin-top: 5px;

      @include media-breakpoint-down(sm) {
        margin-top: 2px;
      }
    }
  }

  .seat-map-container {
    text-align: center;
    position: relative;
    min-height: 420px;

    &.upper {
      margin-top: 48px;
    }

    .icon-spinner {
      width: 40px;
      height: 40px;
      color: $viking-black;
    }

    .flight-seat-info-btn .btn-strong-border {
      margin: 0 auto;
      margin-bottom: 20px;
      width: 290px;

      @include media-breakpoint-down(sm) {
        width: 260px;
      }

      .btn-text-icon {
        height: 24px;
        line-height: 24px;
        vertical-align: middle;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
          height: 20px;
          line-height: 20px;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
      }

      .container-icon {
        margin-left: 10px;

        .icon {
          transform: scale(0.9, 0.9);
        }
      }
    }

    .flight-details-box {
      margin: 0 auto;
      width: 290px;
      position: absolute;
      top: 50px;
      z-index: 100;
      background-color: $viking-white;
      border: 1px solid $viking-black;
      border-top: none;
      text-align: left;
      padding: 20px 27px 36px 34px;
      left: 0;
      right: 0;

      @include media-breakpoint-down(md) {
        font-size: $small-body-copy;
        top: 40px;
        padding-right: 36px;
      }

      @include media-breakpoint-up(md) {
        left: 0;
        right: 0;
      }

      @include media-breakpoint-down(sm) {
        width: 260px;
      }

      .col-12 {
        &:focus {
          box-shadow: $viking-box-shadow-highlight-blue;
        }
      }
    }
  }

  .empty-seat-map {
    position: relative;
    min-height: 652px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      min-height: 394px;
      margin-left: -15px;
    }

    @include media-breakpoint-up(md) {
      width: 492px;
    }

    &:focus {
      box-shadow: $viking-box-shadow-highlight-blue;
    }

    .empty-seatmap-text .btn-link {
      font-weight: 700;
      font-size: $large-body-copy;
    }

    .empty-body {
      height: 652px;
      width: 320px;
      background-color: $viking-light-gray;
      position: absolute;
      left: 86px;

      @include media-breakpoint-down(sm) {
        height: 386px;
        width: 187px;
        left: 52px;
      }

      h6 {
        font-weight: 700;
        padding: 0 3px;

        @include media-breakpoint-down(md) {
          font-size: $medium-body-copy;
        }
      }

      .small-empty-text {
        font-size: $small-body-copy;
        font-weight: 700;

        .btn-link {
          font-weight: 700;
          font-size: $small-body-copy;
        }
      }
    }

    .left-wing-start {
      position: absolute;
      width: 0;
      height: 0;
      border-top: 32px solid transparent;
      border-right: 86px solid $viking-medium-light-gray;
      left: 0;
      top: 113px;

      @include media-breakpoint-down(sm) {
        border-right: 52px solid $viking-medium-light-gray;
        top: 71px;
      }
    }

    .left-wing {
      position: absolute;
      width: 86px;
      height: 0;
      border-top: 32px solid transparent;
      border-bottom: 363px solid $viking-medium-light-gray;
      left: 0;
      top: 113px;

      @include media-breakpoint-down(sm) {
        width: 52px;
        border-bottom: 179px solid $viking-medium-light-gray;
        top: 71px;
      }
    }

    .left-wing-end {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 86px solid $viking-medium-light-gray;
      border-bottom: 32px solid transparent;
      left: 0;
      top: 508px;

      @include media-breakpoint-down(sm) {
        border-left: 52px solid $viking-medium-light-gray;
        top: 282px;
      }
    }

    .right-wing-start {
      position: absolute;
      width: 0;
      height: 0;
      border-top: 32px solid transparent;
      border-left: 86px solid $viking-medium-light-gray;
      left: 406px;
      top: 113px;

      @include media-breakpoint-down(sm) {
        border-left: 52px solid $viking-medium-light-gray;
        left: 239px;
        top: 71px;
      }
    }

    .right-wing {
      position: absolute;
      width: 86px;
      height: 0;
      border-top: 32px solid transparent;
      border-bottom: 363px solid $viking-medium-light-gray;
      left: 406px;
      top: 113px;

      @include media-breakpoint-down(sm) {
        width: 52px;
        border-bottom: 179px solid $viking-medium-light-gray;
        top: 71px;
        left: 239px;
      }
    }

    .right-wing-end {
      position: absolute;
      width: 0;
      height: 0;
      border-right: 86px solid $viking-medium-light-gray;
      border-bottom: 32px solid transparent;
      left: 406px;
      top: 508px;

      @include media-breakpoint-down(sm) {
        border-right: 52px solid $viking-medium-light-gray;
        top: 282px;
        left: 239px;
      }
    }
  }
}
