@import '../../../../css/variables';

.manage-payment-method {
  .form-credit-card,
  .form-ach {
    @include payment-form;
  }

  .additional-actions {
    padding: 8px 0 24px;

    button {
      text-transform: uppercase;
    }

    @include respond-to(false, sm) {
      flex-direction: column;
      align-items: flex-start;

      .checkbox-container {
        padding-bottom: 8px;
      }
    }
  }

  .buttons {
    flex-direction: column;

    .payment-failed {
      color: $viking-red;

      @include body-copy-font(medium);

      padding-top: 8px;
      text-align: center;
    }
  }

  &.has-pre-header {
    .page-header {
      padding-top: 72px;
    }
  }

  .disclaimer-message {
    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
    }

    a {
      text-decoration: underline;
    }
  }
}
