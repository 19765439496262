@import '../../css/variables';

.no-notification {
  .notification {
    text-align: center;
    color: $viking-dark-gray;
  }
}

.notifications .row {
  .notification {
    font-weight: 400;

    .ntf-text {
      @include media-breakpoint-down(sm) {
        width: auto;
      }
    }
  }
}

.notifications .notification-not-viewed.row {
  .notification {
    font-weight: 700;
  }
}

.notifications.notifications-page .row {
  @include media-breakpoint-down(md) {
    padding-left: 0;
  }
}

.notifications.notifications-page .no-notification.row {
  border-bottom: none;
}

.notifications .btn-box .chevron-right-btn {
  padding: 0;
}

.notification-modal .mvj-modal .content .message a {
  font-size: 16px;
}

.notifications .icon-box {
  .icon-ellipse {
    color: $viking-navy-blue;
  }

  @include media-breakpoint-down(sm) {
    width: 16px;
    height: 16px;
    min-width: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
