@import '../../../css/variables';

.reservation-details {
  margin-top: 16px;

  @include media-breakpoint-down(sm) {
    margin-top: 8px;
  }
}

.time-details {
  @include body-copy-font(small);
}

.reservation-title {
  font-weight: bold;

  .availability {
    color: $viking-red;
    font-weight: normal;
    margin-left: 6px;
    text-transform: uppercase;
  }
}
