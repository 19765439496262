@import '../../css/variables';

.the-restaurant.dining-modal-header {
  .reservation-modal-title {
    text-align: center;
  }

  .sub-title {
    position: relative;
    margin-top: 40px;
    margin-bottom: -20px;

    a {
      position: absolute;
      top: -45px;
      right: 0;

      @include media-breakpoint-down(sm) {
        left: 50%;
        margin-left: -50px;
        font-size: 12px;
      }
    }
  }
}

.section-content {
  &.short {
    &.full {
      height: auto;
      max-height: 100%;
    }

    .description {
      display: none;
      max-width: 100%;
    }

    .description:first-child {
      line-height: 20px;
      max-height: 50px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      margin: 0;
      max-width: 100%;
      overflow-wrap: anywhere;
    }
  }
}

.new-modal .new-modal-content .reservation-modal {
  $side-margin-large: -24px;
  $side-margin-small: -16px;
  $bottom-margin-large: -24px;
  $bottom-margin-small: $side-margin-small;

  padding-top: 16px;
  width: calc(100% + -2 * #{$side-margin-large});
  margin: 0 $side-margin-large $bottom-margin-large;

  @include media-breakpoint-down(sm) {
    width: calc(100% + -2 * #{$side-margin-small});
    margin: 0 $side-margin-small $bottom-margin-small;
  }

  .image {
    padding-top: 24px;

    @include media-breakpoint-down(md) {
      padding-top: 16px;
    }
  }

  img {
    position: relative !important;
  }

  .image-carousel-section .carousel.slide .img-error {
    border: $viking-medium-gray-rule;

    img {
      min-height: 480px;
    }
  }

  .sub-title {
    white-space: pre-wrap;
  }

  .icons {
    padding-top: 16px;
  }

  .description {
    @include body-copy-font(small);
  }

  &.mvj-modal .button-with-alert {
    margin-top: 16px;
  }

  &.mvj-modal {
    .content {
      padding: 0;
    }
  }

  .content-outer {
    padding: 24px 0 24px 24px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding: 16px;
    }

    .sub-text {
      font-weight: 400;
      font-style: italic;
      font-size: 16px;
      color: $viking-dark-gray;
      margin-top: 16px;
    }

    .header,
    .description {
      margin-top: 16px;
    }

    .content {
      padding-right: 24px;

      .icon-row {
        width: 170px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    .effort-disclaimer {
      font-size: 14px;
    }

    .horizontal-separator {
      @include media-breakpoint-down(sm) {
        border-top: $viking-thick-medium-gray-rule;
        border-bottom: $viking-thick-medium-gray-rule;
      }
    }

    .sidebar {
      $sidebar-horizontal-padding: 24px;

      padding: 0 0 0 $sidebar-horizontal-padding;

      .col {
        padding: 0 $sidebar-horizontal-padding;
      }

      @include media-breakpoint-down(sm) {
        margin: 20px 0 4px;
        padding: 20px 0;

        .col {
          padding: 0;
        }
      }
    }

    .sub-title {
      @include body-copy-font(small);

      line-height: 16px;
    }

    .reservation-modal-title {
      font-size: 22px;
      font-weight: bold;
      line-height: 28px;

      @include media-breakpoint-down(md) {
        @include body-copy-font(large);
      }

      .availability {
        color: $viking-red;
        font-weight: normal;
        margin-left: 6px;
        text-transform: uppercase;
      }
    }

    .reservation-modal-subtitle {
      @extend .reservation-modal-title;

      padding-bottom: 16px;
    }
  }

  .separator {
    @include media-breakpoint-up(md) {
      border-right: $viking-medium-gray-rule;
      padding-right: 24px;
    }
  }
}

.reservation-modal-container {
  .reservation-modal-col {
    padding-left: 0;
    padding-right: 0;
  }
}

.reservation-modal-side-container {
  @include media-breakpoint-up(md) {
    float: right;
  }
}

button.modal-show-more-button {
  border: none;
  padding: 0;
  margin: 16px 0 0 0;
  font-size: $small-body-copy;
  color: $viking-medium-blue;
  cursor: pointer;

  &:focus {
    box-shadow: none;
  }
}

.dining-modal-container {
  padding-right: 24px;

  @include media-breakpoint-down(sm) {
    padding-right: 0;
  }

  .more-guests-buttons .form-actions-wrapper .multiple-buttons .button-container button.viking-button.btn {
    @include media-breakpoint-down(sm) {
      margin-top: 8px;
    }
  }

  .form-actions-wrapper .multiple-buttons .button-container:first-child button.viking-button.btn {
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  .reservation-modal.mvj-modal .reservation-form .form-actions-wrapper {
    margin-top: 0;
  }

  .make-reservation-header {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    margin-bottom: 32px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }
  }

  .button-sub-text {
    font-size: $small-body-copy;
    line-height: 20px;
  }

  .vertical-separator {
    border-top: $viking-medium-gray-rule;
    margin: 32px 15px;
    width: 100%;

    @include media-breakpoint-down(sm) {
      margin: 24px 15px;
    }
  }
}
