@import '../../../../css/variables';

.stateroom-preferences {
  form > fieldset {
    min-width: 100%;
  }

  .stateroom {
    padding-bottom: 16px;
    font-weight: 700;

    > div {
      padding-right: 16px;

      @include respond-to(false, sm) {
        padding-bottom: 8px;
      }
    }

    .btn-link {
      font-size: 16px;
      font-weight: 700;
    }

    .your-room {
      text-transform: uppercase;
    }
  }

  .preferences-list {
    flex-wrap: wrap;

    @include respond-to(md, false) {
      margin-bottom: 32px;
    }

    @include respond-to(false, md) {
      margin-bottom: 24px;
    }

    &:last-child {
      @include respond-to(md, false) {
        margin-bottom: 48px;
      }

      @include respond-to(false, md) {
        margin-bottom: 40px;
      }
    }

    .beds {
      margin-bottom: 32px;

      .grid-row {
        @include respond-to(false, md) {
          &:not(:last-child) {
            padding-bottom: 16px;
          }
        }

        &:first-child {
          margin-right: 24px;
        }
      }
    }

    .title {
      @include header-font(5);

      margin-bottom: 16px;
    }

    .passenger {
      padding: 0;

      > .checkbox-list,
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .select-section {
      margin-bottom: 0;
    }
  }

  .form-actions .buttons {
    margin: 0;
  }
}
