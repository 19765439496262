@import '../../../css/variables';

.no-data-page {
  z-index: 1000;
  background-color: white;
  padding-top: 115px;
  bottom: 0;
  width: 100%;
  text-align: center;

  @include media-breakpoint-down(sm) {
    padding-top: 90px;
    font-size: $small-body-copy;
  }

  button {
    margin: 40px auto 300px;
    width: 350px;

    @include media-breakpoint-down(md) {
      margin-bottom: 223px;
      width: 283px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 24px;
      margin-bottom: 184px;
      width: 100%;
    }
  }
}
