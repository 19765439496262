@import '../../../css/variables';

.cruise-payments {
  &.cruise-imminent {
    .payment-alert {
      color: $viking-red;

      &.payment-success {
        color: $viking-dark-green;
      }
    }
  }

  .payment-alert-container {
    margin-bottom: 20px;
  }

  .divider {
    border-top: $viking-light-gray-rule;
  }

  .divider:first-child {
    margin-top: 24px;
  }

  @include media-breakpoint-down(lg) {
    .payment-details {
      .line-items {
        line-height: 22px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .payment-details {
      .line-items {
        line-height: 20px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .payment-details {
      .header {
        font-size: 18px;
      }

      .line-items {
        line-height: 22px;
        padding-left: 24px;
        font-size: 16px;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .payment-details {
      .line-items {
        line-height: 20px;
        padding-left: 16px;
        font-size: 14px;
      }

      .header {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .payment-details {
    margin: 24px 0;
  }

  .schedule-payment-wrapper {
    border-top: $viking-light-gray-rule;
    margin-top: 64px;
    padding: 15px;

    @include media-breakpoint-down(sm) {
      margin-top: 40px;
    }

    .schedule-payment-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }

      margin-top: 32px;
    }

    .payment-section-wrapper {
      border: $viking-light-gray-rule;
      margin-top: 24px;
      padding: 24px;

      .scheduled-payment-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;

        @include media-breakpoint-down(sm) {
          font-size: 18px;
        }
      }

      .scheduled-payment-title-wrapper {
        .payment-amount {
          @include media-breakpoint-up(md) {
            text-align: end;
          }
        }
      }

      .scheduled-payment-body {
        margin-top: 24px;
        font-size: $medium-body-copy;

        .viking-button {
          font-size: $medium-body-copy;
          position: relative;
          top: -1px;
        }
      }

      .scheduled-payment-action-wrapper {
        margin-top: 24px;
        background-color: $viking-light-gray;

        .container-icon {
          margin: 12px;
          margin-left: 16px;
          line-height: 1.5;
        }

        .payment-date {
          margin-top: 12px;
          font-weight: bold;
          font-size: $medium-body-copy;

          @include media-breakpoint-down(sm) {
            font-size: $small-body-copy;
          }
        }

        .edit-button {
          text-align: end;
          margin-right: 16px;
        }
      }
    }

    .payment-section-wrapper:first-child {
      margin-right: 32px;
    }
  }
}
