@import '../../css/variables';

.spa-modal-side-content {
  .checkbox {
    width: 100%;
  }

  .radio-section {
    label {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .radio-section,
  .select-section {
    margin: 8px 0;
  }

  .reservation-actions {
    margin-top: 16px;
  }

  .error-external {
    color: $viking-red;
    font-size: $small-body-copy;
    padding-bottom: 16px;
    padding-top: 16px;
  }

  .passenger {
    font-weight: 700;
    text-transform: uppercase;
  }
}
