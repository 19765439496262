@import '../../css/variables';

.air-confirm-modal {
  text-align: center !important;

  h1 {
    margin-top: 0 !important;
    font-family: $open-sans-font-family;
    font-size: 26px !important;
    margin-bottom: 40px;
  }

  .h3-md {
    margin-top: 18px;
    margin-bottom: 68px;
  }

  @include media-breakpoint-down(sm) {
    .h4 {
      font-weight: 700;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }

  .form-actions-group {
    button {
      min-width: 254px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:first-child {
        margin-left: 8px;

        @include media-breakpoint-down(sm) {
          margin-bottom: 8px;
          margin-left: 0;
        }
      }

      &:last-child {
        margin-right: 8px;
      }
    }
  }
}
