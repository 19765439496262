@import '../../css/variables';

.preauth-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .preauth-page {
    width: 100%;
    min-height: 100vh;
    flex-grow: 1;
    flex-shrink: 0;
    background: $viking-white;
    padding: 0 0 109px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-actions-wrapper .form-actions {
    .btn:not(.btn-link):not(.btn-icon) {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .preauth-page-header {
    width: 100%;
    padding-bottom: 32px;
    justify-content: center;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      padding: 70px 32px 32px 32px;
    }

    @include media-breakpoint-up(md) {
      padding: 40px;
      display: -webkit-flex;
      -webkit-justify-content: center;
    }

    .preauth-page-header-image {
      height: 64px;
      position: relative;
    }
  }

  .preauth-page-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .preauth-form-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .preauth-form-container:not(.wide) {
    .preauth-form-content-wrapper {
      max-width: 408px;
      margin: auto;
    }
  }
}

.maintenance-page-wrapper {
  .preauth-form-container:not(.wide) {
    .preauth-form-content-wrapper {
      max-width: 1000px;
    }
  }
}
