.hero-image {
  display: flex;
  justify-content: center;
  width: 100%;

  div {
    width: 100%;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}
