@import '../../css/variables';

.extensions-page {
  .extensions-type-header {
    margin: 32px 0;
    font-weight: bold;
    font-size: 26px;
  }

  .cards {
    .heading {
      .title {
        font-size: 22px;
        margin-bottom: 4px;
      }

      .sub-title {
        text-transform: uppercase;
        margin-bottom: 8px;
      }
    }

    .card-body {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 12px;
    }

    .fixed-width.viking-button .card-subtitle {
      margin-bottom: 0;
    }
  }

  .section-header {
    text-align: center;
  }

  .pre-content {
    @include media-breakpoint-up(sm) {
      padding-left: 12px;
    }

    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    line-height: 20px;
    font-size: 16px;
    color: #000;
    font-weight: 400;
  }
}
