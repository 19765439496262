@import '../../css/variables';

.voyage-not-available-page {
  height: 100vh;

  .msg-text {
    @include body-copy-font(medium);

    text-align: center;

    @include media-breakpoint-down(md) {
      @include body-copy-font(small);
    }

    @include media-breakpoint-down(xs) {
      @include body-copy-font(fine-print);
    }
  }

  .title {
    text-align: center;
  }
}

.viking-logo {
  width: 150px;
  height: 100px;
}
