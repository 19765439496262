@import 'node_modules/@viking-eng/css/src/variables';

.comfort-check-in {
  font-family: $open-sans-font-family;

  .notifications-banner {
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  &.count-down {
    @include media-breakpoint-up(md) {
      padding: 0 150px 0 150px;
    }
  }

  .countdown-title {
    text-align: center;
    font-size: 22px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }

    line-height: 28px;
    padding-bottom: 30px;
  }

  .check-in-title {
    padding-bottom: 24px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 32px;
    }
  }

  .check-in-message {
    font-size: $medium-body-copy;
    padding-bottom: 24px;
  }

  .countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid $viking-gray;
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }

  .countdown-item {
    color: #111;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px 25px 10px 25px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;

    @include media-breakpoint-down(md) {
      margin: 10px -3px 10px -3px;
    }

    @include media-breakpoint-down(sm) {
      margin: 10px -12px 10px -12px;
    }
  }

  .countdown-item span {
    font-size: 14px;
  }

  .countdown-description {
    font-size: 18px;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  .passenger-container {
    align-items: flex-start;

    :last-child {
      &.passenger-name-wrapper {
        margin-top: 24px;

        @include media-breakpoint-up(md) {
          margin-left: 28px;
          margin-top: 0;
        }
      }
    }

    .email-boarding-pass {
      .viking-button {
        @include media-breakpoint-only(lg) {
          width: auto;
        }

        @include media-breakpoint-only(md) {
          width: auto;
          height: 50px;
        }
      }
    }
  }
}
