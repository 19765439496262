@import '../../../css/variables';

.has-sub-download-btn {
  .viking-button.btn.primary-btn.btn-secondary-blue {
    margin-top: 70px;

    @include media-breakpoint-down(md) {
      margin-top: 60px;
    }
  }

  .viking-button.btn.fixed-sub-download-btn.btn-strong-border {
    margin-top: -150px;
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      margin-top: -130px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: -114px;
    }
  }
}

.has-sub-download-btn-canceling {
  padding-bottom: 50px;

  @include media-breakpoint-down(md) {
    padding-bottom: 30px;
  }

  .viking-button.btn.fixed-sub-download-btn.btn-strong-border {
    margin-top: -210px;

    @include media-breakpoint-down(md) {
      margin-top: -175px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: -165px;
    }
  }
}

.new-modal {
  .new-modal-content {
    .shorex-form {
      .passenger.select-time .sub-waiver-container {
        .viking-button.btn.primary-btn.btn-secondary-blue {
          padding: 0;
        }

        .viking-button.btn-strong-border {
          width: 100%;
        }

        .viking-button.btn.btn-secondary-blue.book-now-btn {
          width: 100%;
          text-transform: uppercase;
          margin-top: -30px;
          margin-bottom: 20px;

          @include media-breakpoint-down(sm) {
            margin-top: -10px;
          }
        }

        .checkbox-label {
          line-height: 18px;
          margin-top: 12px;
          margin-bottom: 16px;

          @include media-breakpoint-down(sm) {
            line-height: 30px;
            margin-bottom: 20px;
          }
        }
      }

      .onboard-only-message {
        font-size: $small-body-copy;
        font-weight: bold;
        text-align: center;
      }

      .validation-error-text {
        margin-bottom: 16px;
      }

      .alert-message {
        font-size: $small-body-copy;
        color: $viking-red;
        margin-bottom: 40px;

        a {
          color: $viking-red;
          text-decoration: underline;
        }
      }

      .passenger {
        &:first-child {
          margin-bottom: 8px;
        }

        &.select-time {
          .action-item {
            .message {
              font-weight: bold;
            }
          }
        }

        .system-message {
          color: $viking-red;

          a {
            color: $viking-red;
          }
        }
      }

      .name {
        @include body-copy-font(medium);

        font-weight: bold;
        line-height: 30px;
      }

      .time {
        @include body-copy-font(medium);
      }

      a.status-message {
        @include link-underline;

        &.conflict {
          font-weight: normal;
          color: $viking-red;
        }
      }

      .status-message {
        @include body-copy-font(small);

        color: $viking-black;
        margin-top: 8px;
        font-weight: bold;

        &.cancel-success {
          color: $viking-black;
          font-weight: bold;
        }
      }

      .select-time {
        margin: 8px 0;
      }

      .form-actions-wrapper {
        margin-top: 8px;
      }

      .form-error-message {
        margin-top: 8px;
      }

      .alert-with-link {
        @include body-copy-font(small);
      }
    }
  }

  .sub-safety-video .btn-link {
    text-underline-position: auto;
  }
}

.waiver-pax {
  .container-1,
  .container-2 {
    display: block;
  }
}

.waiver-pax0 {
  .container-2 {
    display: none;
  }

  .container-1 {
    display: block;
    position: relative;
    padding-top: 40px;

    .viking-button.btn.fixed-sub-download-btn.btn-strong-border {
      display: none;
    }

    .action-item .title {
      position: absolute;
      top: 0;
    }
  }
}

.waiver-pax1 {
  .container-1,
  .fixed-sub-download-btn {
    display: none;
  }

  .container-2 {
    display: block;
    position: relative;
    padding-top: 40px;

    .viking-button.btn.fixed-sub-download-btn.btn-strong-border {
      display: none;
    }

    .action-item .title {
      position: absolute;
      top: 0;
    }
  }
}

.sub-waiver-title {
  font-size: $small-body-copy;
  line-height: 16px;
  margin-bottom: 12px;
}

.sub-safety-video {
  margin-top: 16px;
}
