@import '../../css/variables';

.comparison-modal {
  .center-header {
    .sub-title {
      @include body-copy-font(medium);

      @include media-breakpoint-down(sm) {
        a.text-nowrap {
          text-decoration: none;
        }
      }
    }
  }

  hr {
    margin: 24px 0;
    border-top: $viking-light-gray-rule;
  }

  .heading-outer + .section hr:first-child {
    // first section, if the first element is an hr tag,
    // we need to remove top margin so there's not so much space between
    // it and the subtitle above it. if it's any other element, we don't need
    // to worry about styling.
    margin-top: 0;
  }

  .section {
    @include body-copy-font(medium);

    color: $viking-dark-gray;
  }

  .viking-table {
    $side-margin: -24px;

    color: $viking-black;
    width: calc(100% + -2 * #{$side-margin});
    margin: 12px $side-margin 28px;

    @include respond-to(false, sm) {
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }
}
