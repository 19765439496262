@import '../../../css/variables';

.guest-information {
  .guest-info-subtitle {
    @include page-title-font('page-subtitle');

    @include media-breakpoint-down(md) {
      @include page-title-font('page-subtitle-small');
    }
  }
}
