@import '../../../css/variables';

.air-not-available {
  a {
    text-decoration: underline;
  }

  .alerts-banner {
    margin-bottom: 32px;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }
  }

  .thank-you-header {
    font-size: $large-body-copy;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
    }
  }

  .new-engine-text {
    font-size: $large-body-copy;
    margin-bottom: 12px;

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
      margin-bottom: 8px;
    }
  }

  h5 {
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      margin-bottom: 12px;
    }

    @include media-breakpoint-down(sm) {
      font-size: $large-body-copy;
    }
  }

  .travel-services {
    font-size: $large-body-copy;
    margin-top: 8px;
    margin-bottom: 16px;

    @include media-breakpoint-down(md) {
      margin-bottom: 12px;
    }

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
    }

    a {
      text-decoration: underline;
    }
  }

  .complimentary-hotel-transfers {
    font-size: $small-body-copy;
    color: $viking-dark-gray;
    margin-top: 16px;

    @include media-breakpoint-down(md) {
      margin-top: 12px;
    }
  }

  li {
    font-size: $large-body-copy;
    margin-bottom: 4px;
    line-height: 24px;

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
      line-height: 22px;
    }
  }

  .no-viking-air {
    margin-bottom: 24px;
    font-size: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
    }

    a {
      text-decoration: underline;
    }

    h5 {
      margin-bottom: 16px;
      font-weight: 400;

      a {
        text-decoration: none;
      }

      @include media-breakpoint-down(sm) {
        font-size: $medium-body-copy;
        line-height: 22px;
        margin-bottom: 12px;
      }
    }

    ul {
      font-size: 20px;
      margin-bottom: 48px;
      margin-left: 28px;
      padding-inline-start: 16px;

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }

      @include media-breakpoint-down(sm) {
        font-size: $medium-body-copy;
        margin-bottom: 32px;
        margin-left: 12px;
      }

      li {
        font-size: 20px;
        margin-bottom: 4px;
        line-height: 24px;

        @include media-breakpoint-down(sm) {
          font-size: $medium-body-copy;
          line-height: 22px;
        }
      }
    }
  }
}
