@import '../../../css/variables';

.air-faq-container {
  @media print {
    display: none !important;
  }

  margin-top: 48px;

  @include media-breakpoint-down(md) {
    margin-top: 40px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 32px;
  }

  .air-faq-title {
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
    padding-top: 40px;
    border-top: 1px solid $viking-gray;

    @include media-breakpoint-down(sm) {
      padding-top: 32px;
    }
  }

  .air-faq-subtitle {
    margin-bottom: 16px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }
  }

  .questions .icon {
    transform: rotate(90deg);
  }

  .answer ul {
    margin-bottom: 16px;
  }
}

.new-air-heading {
  .heading {
    .title {
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
    }
  }
}

.balance-due-notification {
  width: 100%;

  .banner-wrapper {
    padding-left: 0;
    padding-right: 0;
    margin-top: 32px;
    margin-bottom: 32px;

    .viking-button {
      &.air {
        position: relative;
        bottom: 2px;
      }
    }
  }
}

.air-schedule-change {
  .text {
    font-size: 20px;
    line-height: 26px;
    margin-top: 28px;

    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px;
    }
  }

  .options {
    font-size: 18px;
    line-height: 24px;
    margin-top: 12px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;
    }
  }
}

.air-no-booking-payment {
  .text {
    font-size: 20px;
    line-height: 26px;
    margin-top: 28px;

    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px;
    }
  }

  .banner-notification {
    .viking-button {
      &.air {
        position: relative;
        bottom: 1.5px;
        margin: 3.5px;
      }
    }
  }
}

.air-no-guest-information {
  a {
    text-decoration: underline;
  }

  .text {
    font-size: 20px;
    line-height: 26px;
    margin-top: 28px;

    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 32px;
    }
  }

  .banner-notification {
    .viking-button {
      &.air {
        position: relative;
        bottom: 2px;
      }
    }
  }
}

//cards
.air-not-available,
.abe-air-booking {
  .banner-notification {
    .alerts-banner {
      span {
        margin-right: 8px;
      }
    }
  }

  .cards {
    margin-top: 48px;

    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 32px;
    }

    .card-row {
      margin-bottom: 48px;

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 32px;
      }
    }

    .card-content {
      .viking-button.btn.btn-secondary-blue {
        font-size: $small-body-copy;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }
}

.abe-air-booking {
  .mobile-small-text {
    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
    }
  }

  .air-pending-mute {
    color: $viking-medium-gray;
  }

  .flight-chosen-segment .flight-seg-details {
    .cabin-seat-container {
      .viking-button.btn-link {
        color: $viking-navy-blue;
      }
    }
  }

  .air-pending-container {
    border: 1px solid;
    padding: 8px 12px;
    width: 100%;
    font-size: $large-body-copy;
    font-style: italic;
    border-color: $viking-medium-gray;

    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
    }
  }

  .passenger-flight {
    margin-bottom: 44px;

    @include media-breakpoint-down(md) {
      margin-bottom: 36px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 28px;
    }

    h6:not(.flight-leg-direction-text) {
      margin-bottom: 12px;

      @include media-breakpoint-down(sm) {
        font-size: $small-body-copy;
        margin-bottom: 8px;
      }
    }

    .passenger-name {
      display: inline;
      font-weight: 700;
    }

    .print-btn {
      font-size: 14px;
      font-weight: 700;
      color: #0f3055;
      line-height: 20px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .container-icon {
      font-size: 14px;
    }
  }

  .no-air {
    margin-top: 44px;

    @include media-breakpoint-down(md) {
      margin-top: 36px;
      font-size: $small-body-copy;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 28px;
    }

    .no-air-container {
      border: $viking-thin-black-rule;
      padding: 16px 12px;
    }
  }

  .other-options {
    margin-bottom: 28px;

    @include media-breakpoint-down(md) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  .pre-selected-title {
    margin-bottom: 16px;
  }

  .check-circle-container {
    max-width: 30px;
    margin-right: 16px;

    .icon {
      width: 30px;
      height: 30px;
      color: $viking-dark-green;
    }
  }

  .flight-booked-label-container {
    margin-bottom: 24px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 36px;
    }

    .flight-booked-label {
      span {
        white-space: nowrap;
      }
    }
  }

  .air-buttons-container {
    margin-bottom: 36px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    button {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
        position: relative;
        text-align: center;

        .container-icon {
          position: absolute;
          top: 8px;
          left: 32px;
        }
      }

      .icon {
        margin-right: 16px;
        width: 30px;
        height: 30px;

        @include media-breakpoint-down(md) {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }

      .icon-jet {
        transform: rotate(-45deg);
        width: 34px;
        height: 34px;

        @include media-breakpoint-down(md) {
          width: 30px;
          height: 30px;
        }
      }

      &.view-seat-btn {
        @include media-breakpoint-only(md) {
          padding-left: 14px;
          padding-right: 18px;
        }

        .icon {
          @include media-breakpoint-down(sm) {
            margin-right: 36px;
          }
        }
      }

      &.upgrade-class-btn .icon {
        @include media-breakpoint-down(sm) {
          margin-right: 55px;
        }
      }

      &.change-flight-btn .icon {
        @include media-breakpoint-down(sm) {
          margin-right: 47px;
        }
      }
    }
  }

  h5 {
    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
    }
  }

  .flight-details-container {
    margin-top: 8px;
  }

  .btn-container .btn-secondary-blue {
    width: 250px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .flight-details-segment .baggage-policy {
    text-decoration: underline;
  }

  .cards {
    margin-top: 60px;

    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }
  }

  .hide-flight-details {
    visibility: hidden;
    overflow: hidden;
    height: 0;
  }

  @media print {
    .air-buttons-container,
    .cards,
    .flight-booked-label {
      display: none;
    }

    .pre-selected-title {
      margin-bottom: 8px;
    }

    .hide-flight-details {
      display: block;
    }

    .passenger-flight {
      page-break-after: always;
    }
  }
}

@media print {
  .app-content {
    padding-bottom: 0;
  }

  .mvj-page-header {
    display: none;
  }
}
