@import 'node_modules/@viking-eng/css/src/variables';

@media print {
  body {
    background-color: white;
  }
}

.boarding-pass-wrapper {
  page-break-after: auto;
  margin: 0 24px;

  .boarding-pass {
    border: $viking-thin-gray-rule;
    border-width: 2px;
    border-radius: 15px;

    .route-wrapper {
      padding: 8px 0;

      .viking-header {
        padding-left: 48px;
        width: 20%;

        .logo-image {
          width: 200px;
          padding-top: 38px;
        }
      }

      .boarding-pass-header {
        color: $viking-medium-blue;
        font-size: 40px;
        padding-top: 72px;
        text-align: center;
        width: 60%;
      }

      .route-heading {
        text-align: right;

        .route-label {
          @include header-font(7);

          color: $viking-medium-gray;
        }

        .route-value {
          @include header-font(3);
        }
      }
    }
  }

  .sections-wrapper {
    padding: 0 48px 32px 48px;
    padding-bottom: 0;

    .section-header {
      @include header-font(6);

      justify-content: space-between;
      color: $viking-medium-blue;
      font-weight: bold;
      padding-bottom: 32px;

      .horizontal-line {
        width: 32px;
        border-bottom: 1px solid $viking-medium-blue;
        margin: auto;
      }

      svg {
        font-size: 40px;
      }
    }

    .content-wrapper {
      .content-item {
        padding-bottom: 32px;

        .item-label {
          @include header-font(6);

          color: $viking-medium-gray;
          font-size: $h5-size;
          text-transform: uppercase;
        }

        .item-value {
          @include header-font(4);

          font-size: $h5-size;
          font-weight: bold;
        }
      }
    }

    .boarding-pass-post-text {
      font-size: $h5-size;
      padding-bottom: 32px;
    }
  }

  .journey-text {
    font-family: $garamond-font-family;
    font-size: 40px;
    padding-bottom: 32px;
  }

  .qr-code {
    padding-bottom: 5px;
    text-align: right;
    width: 20%;

    .qr-image {
      width: 100%;
    }
  }

  .description {
    margin-right: 32px;
  }

  .voyager-app-wrapper {
    padding-top: 32px;
    justify-content: space-between;

    .app-title {
      @include header-font(2);
    }

    .link-wrapper {
      .app-store-link {
        width: 240px;
      }

      img {
        height: 72px;
      }

      .app-store-url {
        word-break: break-all;
      }

      :first-child {
        .app-store-url {
          padding-right: 24px;
        }
      }
    }
  }
}
