@import 'node_modules/@viking-eng/css/src/variables';

.onboard-cc-step-wrapper {
  .onboard-cc-step-title {
    @include header-font(6);

    padding-bottom: 16px;

    @include media-breakpoint-up(md) {
      @include header-font(4);

      padding-bottom: 32px;
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 24px;
    }
  }

  .onboard-cc-step-description {
    margin-bottom: 32px;
    font-size: $large-body-copy;

    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
      margin-bottom: 24px;
    }
  }

  .payment-method-row {
    .row-header {
      .button {
        button {
          display: none;
        }
      }
    }
  }
}
