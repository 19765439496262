@import '../../../css/variables';

.payment-confirmation-ntf-wrapper {
  margin-top: 24px;
}

.payment-confirmation {
  text-align: center;
  padding: 40px 0 164px;

  @include respond-to(sm, md) {
    padding: 68px 0 190px;
  }

  @include respond-to(md, false) {
    padding: 84px 0 300px;
  }

  @include respond-to(lg, false) {
    padding: 84px 0 320px;
  }

  .payment-checkout-summary {
    text-align: left;
    margin-top: 32px;
  }

  .title {
    @include header-font(5);

    margin-bottom: 20px;

    @include respond-to(sm, md) {
      margin-bottom: 24px;
    }

    @include respond-to(md, false) {
      margin-bottom: 32px;
      font-size: $h2-size;
    }
  }

  .body-container {
    margin-bottom: 32px;

    @include respond-to(md, false) {
      margin-bottom: 52px;
    }
  }

  .subtitle {
    margin: 0;
    font-size: $large-body-copy;
    color: $viking-black;

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
    }
  }

  .warning {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }

  .icon-wrapper {
    display: block;
    margin-bottom: 12px;
    font-size: 40px;
    color: $viking-dark-green;

    @include respond-to(sm, false) {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 16px;
    }
  }

  button {
    width: 100%;
    max-width: 270px;
    margin: auto auto 20px;
  }

  button.btn-array {
    max-width: unset;
  }
}
