@import '../../../css/variables';

.air-preferences-form {
  .notifications {
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
    }
  }

  .common-section {
    width: 100%;
    padding-top: 16px;

    .per-user {
      @include respond-to(false, md) {
        padding-bottom: 16px;
      }

      &:first-child {
        @include respond-to(md, false) {
          padding-right: 24px;
        }
      }

      &:last-child {
        @include respond-to(md, false) {
          padding-left: 24px;
        }
      }
    }
  }
}
