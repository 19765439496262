@import '../../css/variables';

.add-booking-modal {
  align-items: center;

  .title {
    text-align: center;
  }

  .add-booking-form {
    .add-booking-fields {
      justify-content: center;
      margin-bottom: 8px;
      margin-top: 24px;

      @include media-breakpoint-up(md) {
        margin-bottom: 32px;
        margin-top: 48px;
      }
    }

    .accept-ptc-fields {
      justify-content: center;
      margin-bottom: 0;
      margin-top: 24px;

      @include media-breakpoint-up(md) {
        margin-bottom: 8px;
        margin-top: 40px;
      }

      .guest-select-title {
        margin-bottom: 16px;
      }

      .add-booking-form-guest-select {
        margin-bottom: 32px;
      }
    }

    .add-booking-fields,
    .accept-ptc-fields {
      .add-booking-form-input-wrapper {
        .input-text-section {
          .form-group {
            margin-bottom: 8px;
          }
        }

        .checkbox-section {
          margin-bottom: 8px;
        }

        .link {
          color: $viking-black;

          @include link-underline;
        }
      }
    }

    .add-booking-success {
      justify-content: center;
      text-align: center;

      .icon-wrapper {
        margin-top: 10px;
        margin-bottom: 6px;
        display: block;
        font-size: 40px;
        color: $viking-dark-green;

        @include media-breakpoint-up(md) {
          display: inline-block;
          margin-bottom: 14px;
        }
      }

      .confirmation-text {
        margin-bottom: 16px;

        @include media-breakpoint-up(md) {
          margin-bottom: 32px;
        }
      }
    }

    .form-actions-wrapper {
      .viking-button.btn-link {
        font-size: 16px;
      }

      .form-actions {
        .buttons {
          margin-bottom: 0;

          @include media-breakpoint-up(md) {
            margin-bottom: 8px;
          }

          .btn {
            @include media-breakpoint-up(md) {
              width: 254px;
            }
          }
        }
      }
    }
  }
}
