@import '../../css/variables';

.cart-price-modal {
  .buttons button {
    width: 254px;
    height: 50px;
    margin-top: 32px;
    margin-top: 4px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 0;
      height: 40px;
      margin-bottom: 12px;
    }
  }

  .cart-item-description {
    flex: 0 0 65%;
    max-width: 65%;

    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .cart-item-wrapper .cart-item-image {
    @include media-breakpoint-down(md) {
      margin-bottom: 5px;
    }
  }

  .cart-item-wrapper .cart-item-main-content .cart-item-details-buttons {
    .cart-item-buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      & > .loading-icon {
        padding-right: unset;
      }
    }
  }

  .cart-item-list-wrapper .cart-item-list-content {
    @include media-breakpoint-down(sm) {
      margin-bottom: 32px;
    }
  }

  .cart-item-list-wrapper {
    margin: 0;

    .cart-item-wrapper {
      .cart-item-details-buttons .cart-item-buttons {
        background-color: transparent;
        border: 2px solid $viking-navy-blue;
        color: $viking-navy-blue;
        text-transform: uppercase;
        width: 150px;
        text-align: center;
      }

      .cart-item-details-buttons {
        @include media-breakpoint-up(md) {
          position: relative;

          .cart-item-buttons {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 50px;
          }
        }
      }

      .cart-item-main-content .cart-item-details-buttons .cart-item-buttons > button {
        text-decoration: none;
        width: 100%;
        text-align: center;
        font-size: $large-body-copy;
        line-height: 24px;

        @include media-breakpoint-down(sm) {
          font-size: $small-body-copy;
        }
      }

      @include media-breakpoint-down(sm) {
        padding-bottom: 40px;
        position: relative;

        .cart-item-details-buttons {
          position: initial;
        }

        .cart-item-main-content {
          position: initial;

          .cart-item-details-buttons .cart-item-buttons {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 40px;

            button {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .title {
    text-align: center;
    font-size: 26px;
    margin-top: 12px;
    font-weight: 700;
    margin-bottom: 32px;

    @include media-breakpoint-down(sm) {
      font-size: 22px;
      margin-bottom: 16px;
    }
  }

  .message {
    text-align: center;
    padding: 16px 0 24px 0;
    font-size: $large-body-copy;

    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
    }
  }
}
