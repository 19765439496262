@import '../../css/variables';

.request-invoice-modal {
  align-items: center;

  .title {
    margin-bottom: 16px;
  }

  .request-invoice-form {
    .request-invoice-fields {
      justify-content: center;
      margin-bottom: 8px;
      text-align: center;

      .title {
        text-transform: uppercase;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 24px;
      }
    }

    .request-invoice-success {
      justify-content: center;
      text-align: center;

      .icon-wrapper {
        margin-bottom: 6px;
        display: block;
        font-size: 40px;
        color: $viking-dark-green;

        @include media-breakpoint-up(md) {
          display: inline-block;
          margin-bottom: 14px;
        }
      }

      .confirmation-text {
        margin-bottom: 16px;

        @include media-breakpoint-up(md) {
          margin-bottom: 32px;
        }
      }
    }

    .form-actions-wrapper .form-actions .buttons {
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        margin-bottom: 8px;
      }

      .btn {
        @include media-breakpoint-up(md) {
          width: 254px;
        }
      }
    }
  }
}
