@import '../../css/variables';

.new-modal .new-modal-content {
  .modal-traveldocuments-mvj {
    clear: both;
    overflow: hidden;

    .heading {
      margin: 24px 0 16px;
    }

    .title {
      text-align: center;

      @include header-font(2);
    }

    .subheading {
      background: $viking-light-gray;
      padding: 24px 16px;
      margin-bottom: 16px;
    }

    .body {
      @include body-copy-font(medium);

      margin: 0;
    }

    .h4 {
      font-weight: 600;
      margin-bottom: 16px;
    }

    .header-row {
      font-weight: bold;
      margin-bottom: 8px;

      [class*='col'] {
        border-bottom: $viking-medium-gray-rule;
        padding: 8px 0;

        @include media-breakpoint-up(md) {
          padding: 8px 16px;
        }
      }
    }

    .body-row {
      [class*='col'] {
        padding: 8px 0;

        @include media-breakpoint-up(md) {
          padding: 8px 16px;
        }

        &:last-child {
          padding-top: 0;

          @include media-breakpoint-up(md) {
            padding: 8px 0;
          }
        }
      }
    }

    .btn {
      width: 100%;
    }
  }
}
