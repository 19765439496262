@import '../../css/variables';

.cart-time-modal {
  .cart-item-wrapper .cart-item-main-content .cart-item-title-price .cart-item-title {
    max-width: 63%;
    line-height: 18px;
  }

  .cart-item-list-wrapper .cart-item-list-content {
    @include media-breakpoint-down(sm) {
      margin-bottom: 32px;
    }
  }

  .cart-item-container {
    position: relative;

    .cart-item-details-buttons {
      position: initial;
    }

    .cart-item-main-content {
      position: initial;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 70px;
    }

    .cart-item-wrapper .cart-item-main-content .cart-item-details-buttons .cart-item-buttons {
      display: none;
    }

    .cart-item-wrapper {
      .cart-item-image {
        min-height: 128px;
      }
    }

    .viking-button.btn-secondary-blue {
      position: absolute;
      top: 0;
      right: 0;
      width: 160px;
      font-size: $medium-body-copy;
      padding: 5px;

      @include media-breakpoint-down(sm) {
        left: 0;
        bottom: 0;
        top: auto;
        height: 34px;
        width: 100%;
      }
    }

    .cart-item-price {
      display: none;
    }

    .conflict-time {
      position: absolute;
      bottom: 0;
      left: 176px;
      font-weight: 700;

      @include media-breakpoint-down(sm) {
        left: 84px;
        bottom: 46px;
      }

      @media (min-width: 576px) and (max-width: 767.98px) {
        left: 144px;
        bottom: 56px;
      }
    }
  }

  .buttons button {
    width: 254px;
    height: 50px;
    margin-top: 32px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .cart-item-list-wrapper {
    margin: 0;
  }

  .title {
    text-align: center;
    font-size: 26px;
    margin-bottom: 16px;
    margin-top: 12px;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
  }

  .message {
    text-align: center;
    padding: 0 0 32px 0;
    font-size: $large-body-copy;
    line-height: 26px;

    @include media-breakpoint-down(sm) {
      padding: 0 0 16px 0;
      font-size: $small-body-copy;
      line-height: 22px;
    }
  }
}
