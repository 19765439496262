@import '../../css/variables';

.confirmation-modal {
  text-align: center;

  .h3-md {
    margin-top: 18px;
    margin-bottom: 68px;
  }

  @include media-breakpoint-down(sm) {
    .h4 {
      font-weight: 700;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }

  .confirmation-title {
    text-align: center;
    font-weight: bold;
    font-size: 26px;

    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
  }

  .text-content {
    margin: auto;

    .confirmation-message {
      font-size: $medium-body-copy;
      margin: 24px 0 32px;
      width: 524px;
      text-align: center;

      @include media-breakpoint-down(sm) {
        width: 262px;
      }
    }
  }

  .form-actions-group {
    margin: auto;

    button {
      min-width: 254px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:first-child {
        margin-left: 8px;

        @include media-breakpoint-down(sm) {
          margin-bottom: 8px;
          margin-left: 0;
        }
      }

      &:last-child {
        margin-right: 8px;
      }
    }
  }
}
