@import '../../../css/variables';

.select-payment-method {
  .save-ach {
    font-weight: bold;
    font-size: $small-body-copy;
    margin-bottom: 8px;
    line-height: 20px;
  }

  .save-ach-notAvailable {
    color: $viking-red;
    line-height: 20px;
  }

  .radio-section:not(.with-img) {
    height: auto;
  }

  .input-grid .grid-row:not(:last-child) {
    margin-bottom: 16px;
  }
}
