@import '../../css/variables';

.dining-modal-container.row .invite-guest-group .btn-link {
  @include media-breakpoint-down(sm) {
    margin-left: -15px;
    margin-bottom: 12px;
  }
}

.reservation-modal.mvj-modal .reservation-form {
  .reservation-guests {
    margin-top: 8px;
  }

  .form-actions-wrapper {
    margin-top: 8px;
  }

  .invitees {
    margin: 8px 0 16px;
  }

  .validation-error-text {
    margin-bottom: 16px;
    margin-top: 8px;

    .btn-link {
      font-size: 13px;
      margin-bottom: 2px;
    }

    a {
      color: $viking-red;
      text-decoration: underline;
    }
  }

  .reservation-guest-widget {
    .guest {
      text-transform: uppercase;
    }

    .reservation-message {
      font-weight: 400;

      &.non-urgent {
        color: #000;
      }
    }
  }

  .reservation-actions {
    margin-top: 8px;
  }

  .ssbp-reservation-actions {
    display: none;
  }

  .alert-with-link {
    @include body-copy-font(small);
  }

  .invite-guests {
    margin: 8px 0;

    @include body-copy-font(small);
  }

  .select-section,
  .input-text-section .form-group {
    margin-bottom: 8px;
  }

  .checkbox-section .checkbox-label {
    font-weight: 700;
  }

  .select-section .select-custom-control,
  .form-control.has-success,
  .form-control.has-success:focus {
    background: $viking-white;
    text-align: left;
  }

  .btn-secondary {
    margin-top: 16px;

    .btn {
      width: 100%;
    }
  }

  .uk-olb-message {
    font-size: 14px;
    font-weight: bold;
  }
}

.reservation-modal.mvj-modal .dining-modal-container .reservation-form {
  .reservation-guests {
    margin-top: 16px;
  }

  .select-section {
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.dining-modal-container.row {
  .reservation-modal-title {
    text-align: center;
    margin-bottom: 12px;
  }

  .col-12 .dining-modal-header div.sub-title {
    float: right;

    @include media-breakpoint-down(sm) {
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }

  .col-12 .the-restaurant.dining-modal-header div.sub-title {
    float: left;
    margin-bottom: 1px;
    line-height: 20px;

    @include media-breakpoint-down(sm) {
      float: left;
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
      font-size: $small-body-copy;

      a {
        font-size: 12px;
      }
    }
  }

  .description {
    margin-top: 0;

    strong {
      margin-bottom: 8px;
      display: inline-block;
    }
  }

  .multiple-buttons .button-container button.viking-button.btn {
    text-transform: uppercase;
  }

  .dining-alerts-container {
    text-align: center;
    margin-bottom: 24px;
    margin-top: -28px;

    @include media-breakpoint-down(sm) {
      margin-top: -20px;
    }
  }

  .dining-modal-side-content {
    .reservation-form {
      .validation-error-text {
        &.some-dining-unavailable {
          color: $viking-black;
        }
      }

      .dining-alerts-container {
        .validation-error-text {
          margin-bottom: 0;
          margin-top: 4px;
        }
      }

      .reservation-guests {
        .validation-error-text {
          display: none;
        }
      }
    }
  }

  .table-for-two-info {
    font-size: $small-body-copy;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    h6 {
      font-weight: 700;
      margin-bottom: 4px;
    }
  }

  .table-for-two-form-btn {
    .button-sub-text {
      display: none;
    }

    .form-actions-wrapper .multiple-buttons .button-container {
      @include media-breakpoint-down(sm) {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%;

        &:first-child {
          padding-right: 8px;
        }

        &:last-child {
          padding-left: 8px;
        }

        button.viking-button.btn {
          margin-top: 0;
        }
      }
    }
  }

  .table-for-two-party-size {
    font-weight: 700;
    font-size: $medium-body-copy;
    margin-top: 10px;
    width: 100%;

    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-top: 12px;
    }
  }

  .table-for-two-select-day {
    @include media-breakpoint-up(md) {
      padding-right: 8px;
    }
  }

  .table-for-two-select-time {
    @include media-breakpoint-up(md) {
      padding-left: 8px;
    }
  }

  .reservation-modal.mvj-modal .reservation-form .reservation-actions.table-for-two-btn-container {
    @include media-breakpoint-up(md) {
      margin-top: -14px;
    }
  }

  .dining-modal-side-content .table-for-two-btn-container,
  .dining-modal-side-content .more-guests-buttons {
    div.form-actions-wrapper {
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    .multiple-buttons {
      @include media-breakpoint-up(md) {
        .button-container button.viking-button.btn {
          height: 50px;
        }

        .button-container:first-child {
          padding-right: 8px;
        }

        .button-container:last-child {
          padding-left: 8px;
        }
      }
    }
  }

  .invite-guest-group {
    .more-guests-link {
      margin-top: -38px;
      margin-bottom: 8px;
      text-align: right;
    }

    .row {
      position: relative;

      .btn-icon {
        position: absolute;
        right: 24px;
        top: 17px;
        z-index: 1;

        @include media-breakpoint-down(sm) {
          left: calc(100% - 40px);
          top: 12px;
          right: unset;
          z-index: 1;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .guest-booking-number {
        padding-right: 8px;
      }

      .guest-name {
        padding-left: 8px;
      }
    }
  }

  .invite-guest-info {
    font-size: $large-body-copy;
    font-weight: 700;
    margin-bottom: 4px;
  }
}
