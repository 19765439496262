@import '../../css/variables';

.ancillaries-modal {
  font-size: $large-body-copy;

  @include media-breakpoint-down(sm) {
    font-size: $small-body-copy;
  }

  .title {
    text-align: center;
    margin-bottom: 44px;
    margin-top: 18px;

    &:focus {
      box-shadow: $viking-box-shadow-highlight-blue;
    }

    @include media-breakpoint-down(sm) {
      font-size: 22px;
      margin-bottom: 32px;
      margin-top: 12px;
    }
  }

  .icon-cancel-circle {
    width: 24px;
    height: 24px;

    @include media-breakpoint-down(sm) {
      width: 18px;
      height: 18px;
    }
  }

  .col-ancillary {
    @include media-breakpoint-down(sm) {
      margin: 0 15px;
    }
  }

  .ancillary {
    margin-bottom: 8px;

    &:focus {
      box-shadow: $viking-box-shadow-highlight-blue;
    }

    .icon {
      width: 36px;
      height: 36px;
      margin-right: 16px;

      @include media-breakpoint-down(sm) {
        width: 24px;
        height: 24px;
      }
    }
  }

  .note {
    color: $viking-black;
    margin-bottom: 20px;
    margin-top: -4px;

    &:focus {
      box-shadow: $viking-box-shadow-highlight-blue;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }
  }

  .spinner-container {
    margin-bottom: 24px;
  }

  .baggage-link {
    margin-bottom: 20px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }

    button {
      &:focus {
        box-shadow: $viking-box-shadow-highlight-blue;
      }
    }
  }

  .open-link-col {
    padding-left: 8px;

    .icon {
      height: 20px;
      width: 20px;

      @include media-breakpoint-down(sm) {
        width: 16px;
        height: 16px;
      }
    }
  }
}
