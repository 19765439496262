@import '../../css/variables';

.msg-text {
  text-align: center;

  @include body-copy-font(medium);

  @include media-breakpoint-down(md) {
    @include body-copy-font(small);
  }

  @include media-breakpoint-down(xs) {
    @include body-copy-font(fine-print);
  }
}

.oops-Page {
  background: $viking-white;
  height: 100vh;
}

.viking-logo {
  width: 150px;
  height: 100px;
}
