@import '../../css/variables';

.depsit-due-container {
  border-bottom: $viking-light-gray-rule;
  margin-bottom: 32px;

  .payment-details {
    .header {
      &.deposit-due {
        color: $viking-red;
      }
    }
  }
}
