@import '../../css/variables';

.extension-modal-main-content {
  .extensions-modal-header {
    .heading {
      @include respond-to(false, md) {
        margin-top: 0;
      }

      .title {
        font-size: 22px;

        @include respond-to(false, md) {
          font-size: 18px;
        }
      }

      .sub-title {
        @include respond-to(false, md) {
          font-size: 14px;
        }
      }
    }
  }

  .description {
    @include body-copy-font(small);

    .content-section-heading {
      font-size: 16px;
      font-weight: bold;
    }

    .description-body-text {
      margin: 8px 0;
    }
  }

  .disclaimer-description {
    font-size: 14px;
    font-style: italic;
    border-top: 1px solid #c0c0c0;
    padding-top: 14px;
  }
}
