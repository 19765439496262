.user-profile-form {
  .requirements {
    font-size: 14px;
    margin: 8px 0;
  }

  .actions button {
    width: 100%;
  }

  .message {
    margin-top: 8px;
  }
}
