@import '../../css/variables';

.card-details-modal {
  .image {
    padding-top: 24px;

    @include respond-to(false, md) {
      padding-top: 16px;
    }
  }

  .icons {
    padding-top: 16px;
  }

  @include body-copy-font(medium);

  @include respond-to(false, md) {
    @include body-copy-font(small);
  }

  .button-with-alert {
    @include respond-to(false, sm) {
      margin-top: 16px;
    }
  }

  .row {
    @include print-break-outside;

    .card {
      width: 100%;
      padding: 32px 0;

      &:last-child {
        border-bottom: $viking-light-gray-rule;
      }

      @include respond-to(md, lg) {
        padding: 24px 0;
      }

      @include respond-to(false, md) {
        padding-top: 16px;
      }

      .card-content {
        padding: 0 24px 16px 24px;

        @include media-breakpoint-down(sm) {
          padding-top: 16px;
        }
      }
    }
  }

  .modal-heading {
    border-bottom: $viking-light-gray-rule;
    padding-bottom: 8px;
  }

  .grouped-section {
    @include print-break-outside;

    .section-title {
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 5px;
      margin-top: 32px;
      line-height: 23px;
    }
  }
}
