@import 'node_modules/@viking-eng/css/src/variables';

.request-invoice-form {
  .requirements {
    font-size: 14px;
    margin: 8px 0;
  }

  .actions button {
    width: 100%;
  }

  .message {
    margin-top: 8px;
  }

  .invoice-title {
    text-align: center;
    font-weight: bold;
  }

  .description-content {
    text-align: center;
    font-size: $medium-body-copy;
    padding: 16px 0 16px 0;

    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
    }
  }

  .invoice-button {
    text-transform: uppercase;
  }
}
