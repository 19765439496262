@import '../../css/variables';

.cart-sold-out-modal {
  .cart-item-list-wrapper {
    margin: 0;
  }

  .sold-out-content {
    padding: 0 16px;
  }

  .title {
    text-align: center;
    padding-top: 24px;

    @include respond-to(false, md) {
      padding-top: 12px;
    }
  }

  .message {
    text-align: center;
    padding: 16px 0 24px 0;

    @include body-copy-font(large);

    @include respond-to(false, md) {
      padding: 8px 0 16px 0;

      @include body-copy-font(small);
    }
  }

  .buttons button {
    width: 254px;
    height: 50px;

    @include respond-to(false, md) {
      width: 288px;
      height: 40px;
    }
  }
}
