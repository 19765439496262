@import '../../../css/variables';

.payment-alert-container {
  margin-bottom: 20px;
}

.empty-cart-message {
  color: $viking-dark-gray;

  @include respond-to(false, sm) {
    text-align: center;
  }

  a {
    @include link-underline;
  }
}
