@import '../css/variables';

.new-modal .new-modal-content .mvj-modal {
  clear: both;
  padding-left: 4px;
  padding-right: 4px;

  a {
    color: $viking-black;
    text-decoration: underline;
  }

  .btn-link {
    text-decoration: underline;
  }

  .alert-with-link a {
    color: $viking-red;
  }

  .content {
    padding: 24px 16px;

    @include respond-to(false, md) {
      padding: 16px;
    }

    .checkbox-grid {
      padding-top: 40px;

      @include respond-to(false, md) {
        padding-top: 32px;
      }
    }

    .sub-title {
      a {
        color: $viking-black;
      }
    }
  }

  .heading {
    @include respond-to(sm, md) {
      margin-right: 16px;
    }

    @include respond-to(md, false) {
      margin-right: 24px;
    }
  }

  .button-with-alert {
    @include respond-to(false, sm) {
      margin-top: 24px;
    }
  }

  .center-header {
    .heading {
      padding: 18px 0 24px 0;
      margin: auto;
      max-width: 610px;

      .title {
        @include header-font(3);

        text-align: center;
      }

      .sub-title {
        $font-size: $medium-body-copy;

        text-align: center;
        margin-top: 16px;
      }

      @include respond-to(false, md) {
        padding-top: 12px;
        max-width: 458px;

        .sub-title {
          $font-size: $small-body-copy;

          margin-top: 8px;
        }
      }
    }
  }
}

.picture-modal {
  padding-left: 4px;
  padding-right: 4px;
}

.picture-modal,
.stateroom-modal {
  .image-carousel-section {
    $side-margin-large: -24px;
    $side-margin-small: -16px;

    width: calc(100% + -2 * #{$side-margin-large});
    margin: 0 $side-margin-large;

    @include respond-to(false, md) {
      width: calc(100% + -2 * #{$side-margin-small});
      margin: 0 $side-margin-small;
    }
  }
}

.modal-scroll-not-allowed {
  .header {
    .header-container {
      z-index: 99;
    }
  }
}
