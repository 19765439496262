@import '../../css/variables';

.checkbox-list {
  .checkbox-group {
    margin-bottom: 24px;

    .group-name {
      height: 16px;
      font-size: 16px;
      line-height: 10px;
      letter-spacing: 0.26px;
      margin-bottom: 16px;
    }
  }

  .checkbox {
    margin-bottom: 8px;

    .checkbox {
      &-label {
        font-size: 18px;
        line-height: 30px;

        @include respond-to(false, md) {
          padding-left: 10px;
        }
      }

      &-checkmark {
        height: 30px;
        width: 30px;
      }
    }
  }
}
