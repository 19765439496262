@import '../../css/variables';

#airPlusModal {
  .sub-title {
    margin-top: 16px;
  }
}

.reservation-guest-widget {
  &:nth-child(2) {
    margin-bottom: 16px;
  }

  .guest-name,
  .reservation,
  .itinerary-conflict {
    @include body-copy-font(medium);
  }

  .guest {
    display: flex;
    justify-content: space-between;

    &.checkbox {
      line-height: 30px;
      text-transform: uppercase;
    }

    .name {
      font-weight: bold;
    }

    .checkbox {
      width: 100%;
      font-weight: bold;
    }
  }

  .reservation-message,
  .itinerary-conflict {
    color: $viking-red;
    font-size: 14px;
  }

  .reservation-message {
    margin-top: 8px;
    color: $viking-red;
    font-weight: bold;
  }

  .validation-error-text {
    margin: 16px 0;
  }

  .itinerary-conflict {
    text-decoration: underline;
  }

  .reservation-button button {
    margin-top: 10px;
    width: 100%;
  }

  .reservation-time {
    margin-top: 8px;
  }
}
