@import 'node_modules/@viking-eng/css/src/variables';

.fit-to-travel-survey {
  font-family: $open-sans-font-family;

  .fit-to-travel-survey-title {
    padding-bottom: 24px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 32px;
    }
  }

  .banner-notification {
    padding-bottom: 24px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 32px;
    }
  }

  .alerts-banner {
    background-color: $viking-light-gray;
    padding: 24px 24px 8px 24px;
  }

  .fit-to-travel-survey-message {
    font-size: $medium-body-copy;
    padding-bottom: 24px;
  }

  .passenger-container {
    align-items: flex-start;

    :last-child {
      &.passenger-name-wrapper {
        margin-top: 24px;

        @include media-breakpoint-up(md) {
          margin-left: 28px;
          margin-top: 0;
        }
      }
    }
  }

  .fit-to-travel-survey-disable-message {
    color: $viking-red;
    font-size: $large-body-copy;
    padding-bottom: 24px;
    font-weight: 100;

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
    }
  }
}
