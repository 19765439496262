@import '../../css/variables';

.picture-modal {
  .heading {
    margin-bottom: 18px;

    @include respond-to(false, md) {
      margin-bottom: 12px;
    }
  }

  .content {
    margin-top: 16px;

    .caption {
      line-height: 20px;
      color: $viking-dark-gray;

      @include body-copy-font(small);

      margin: 6px 0 0 24px;

      @include media-breakpoint-down(sm) {
        margin: 6px 0 0 16px;
      }
    }
  }

  &.mvj-modal {
    .heading {
      text-align: center;
    }
  }
}
