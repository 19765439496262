@import '../../css/variables';

.documents-page {
  .confirm-modal {
    .form-actions-group {
      text-align: center;
      margin-top: 8px;

      @include media-breakpoint-down(sm) {
        margin-top: 16px;
      }

      .form-actions-wrapper {
        text-align: center;
        display: inline-block;

        &:first-child {
          margin-right: 16px;

          @include media-breakpoint-down(sm) {
            margin: 0;
          }
        }

        .form-actions {
          margin-top: 0;

          .buttons {
            display: inline-block;

            @include media-breakpoint-down(sm) {
              margin: 0;
            }

            .btn {
              width: 303px;

              @include media-breakpoint-down(sm) {
                width: 223px;
              }
            }
          }
        }
      }
    }
  }
}
