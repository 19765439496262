@import 'node_modules/@viking-eng/css/src/variables';

.air-header-container {
  padding-top: 28px;
  margin-bottom: 40px;
  border-bottom: $viking-thick-medium-gray-rule;

  @include media-breakpoint-down(md) {
    padding-top: 20px;
    margin-bottom: 32px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 12px;
    margin-bottom: 16px;
  }

  .airheader-subtitle {
    font-family: $open-sans-font-family;
    line-height: 24px;
    margin: 4px 0;

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
    }
  }

  .airheader-subtitle-placeholder {
    height: 26px;

    @include media-breakpoint-down(md) {
      height: 24px;
    }
  }
}

.air-header-container.done {
  margin-bottom: 0;
}
