@import '../../css/variables';

.seating-preference {
  .input-grid > .label {
    @include body-copy-font(medium);

    font-weight: 700;
    margin-bottom: 16px;
  }

  @include respond-to(sm, md) {
    .grid-column:not(:first-child) {
      padding-left: 48px;
    }
  }

  @include respond-to(md, xl) {
    .grid-column:not(:first-child) {
      padding-left: 85px;
    }
  }

  @include respond-to(xl, false) {
    .grid-column:not(:first-child) {
      padding-left: 64px;
    }
  }

  img {
    height: 30px;
  }
}

.air-container {
  .seating-preference {
    @include respond-to(xl, false) {
      .grid-column:not(:first-child) {
        padding-left: 0;
      }
    }
  }
}
