@import '../../../css/variables';

.vouchers-credits {
  .section-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .voucher-tooltip {
    @include body-copy-font(medium);

    margin-bottom: 32px;
  }

  .line-item:not(:last-child) {
    margin-bottom: 16px;

    @include respond-to(false, sm) {
      margin-bottom: 8px;
    }
  }

  .passengers {
    @include body-copy-font(large);

    @include respond-to(false, md) {
      @include body-copy-font(medium);
    }

    .name {
      @include body-copy-font(large);

      @include respond-to(false, sm) {
        margin-bottom: 8px;
      }
    }

    .name,
    .type-label {
      font-weight: bold;
    }

    li:not(:last-child) {
      margin-bottom: 32px;

      @include respond-to(false, sm) {
        margin-bottom: 24px;
      }
    }
  }
}
