@import '../../css/variables';

.guide-modals-flow {
  position: absolute;

  @include media-breakpoint-down(sm) {
    position: fixed;
  }

  @include media-breakpoint-down(sm) {
    left: 15px;
    right: 15px;
  }

  .close-button {
    position: absolute;
    top: 18px;
    right: 20px;
  }
}

.modal-arrow-container {
  margin-top: -18px;
  width: 30px;
  float: left;
  margin-left: 40px;
}

.modal-page-number {
  position: absolute;
  top: 20px;
  left: 20px;
}

.modal-arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid $viking-white;
}

.home-guide-modal-start.confirm-modal {
  .text-content {
    text-align: center;
  }

  .title {
    margin-top: 10px;
  }

  .message {
    margin-bottom: 32px;
    display: inline-block;
    margin-right: 12px;

    @include media-breakpoint-down(sm) {
      font-size: $medium-body-copy;
      margin-bottom: 24px;
    }
  }
}

.home-guide-modal-end.confirm-modal {
  .message {
    display: inline;
  }
}

.guide-modal-2 {
  margin-left: 40%;

  @include media-breakpoint-down(md) {
    margin-left: 35%;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}

.guide-modal-3,
.guide-modal-5 {
  margin-left: 40%;

  @include media-breakpoint-down(md) {
    margin-left: 35%;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}

.home-guide-modal-start.confirm-modal .form-actions-group .form-actions-wrapper {
  .form-actions {
    .buttons {
      flex-direction: row-reverse !important;
      margin: 0;

      .btn {
        width: 160px;
        text-transform: uppercase;
        margin: 0;

        @include media-breakpoint-down(sm) {
          width: 130px;
          min-width: 130px;
        }

        &.btn-secondary-gray {
          background-color: transparent;
          border: none;
          color: $viking-navy-blue;
          text-transform: capitalize;
          text-decoration: underline;
          width: 130px;
          min-width: 130px;
        }
      }
    }
  }
}

.home-guide-modal-start.home-guide-modal-end.confirm-modal .form-actions-group .form-actions-wrapper {
  .form-actions {
    .buttons {
      .btn {
        width: 160px;
        text-transform: uppercase;
        margin: 0;
        margin-top: 32px;

        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-top: 24px;
        }
      }
    }
  }
}

.home-guide-modal-start.home-guide-modal-end.confirm-modal .title {
  margin-bottom: 16px;
}

.home-guide-modal.container {
  .new-modal-content {
    width: 690px;
    padding: 30px;

    .confirm-modal {
      background-color: $viking-white;
      padding: 30px;
      width: 690px;

      @include media-breakpoint-down(lg) {
        width: 530px;
      }

      @include media-breakpoint-down(md) {
        width: 430px;
      }

      @include media-breakpoint-down(sm) {
        width: auto;
      }
    }

    .new-modal-controls-wrapper {
      .close-button {
        visibility: hidden;
      }
    }
  }

  .confirm-modal .form-actions-wrapper .form-actions:not(.form-actions-stacked) .buttons {
    margin-top: 32px;
    flex-direction: row !important;

    .btn {
      width: 160px;
      margin: 0;

      @include media-breakpoint-down(sm) {
        width: 151px;
        min-width: 151px;
      }

      &.btn-secondary-gray {
        background-color: transparent;
        border: 2px solid $viking-navy-blue;
        color: $viking-navy-blue;
        text-transform: uppercase;
        margin-right: 10px;
      }
    }
  }

  &.new-modal {
    background-color: transparent;
  }
}
