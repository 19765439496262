@import '../../css/variables';

.carousel-wrapper {
  background-color: $viking-navy-blue;
  height: 0;
  margin: 0;

  @include media-breakpoint-down(md) {
    margin: 26px auto;
  }

  .carousel-container {
    background-color: $viking-navy-blue;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    position: absolute;
    width: 100%;

    .carousel {
      height: 277px;
      margin: 0 auto;
      padding-bottom: 32px;
      padding-top: 32px;
      width: 1338px;

      @include media-breakpoint-down(lg) {
        height: 278px;
        width: 980px;
      }

      @include media-breakpoint-down(md) {
        height: 220px;
        padding-bottom: 24px;
        padding-top: 24px;
        width: 768px;
      }

      @include media-breakpoint-down(sm) {
        height: 235px;
        width: 100%;
      }

      .title {
        color: $viking-white;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 20px;
        padding-left: 8px;

        @include media-breakpoint-down(md) {
          font-size: 20px;
          line-height: 24px;
          margin: 0 auto 16px auto;
          padding-left: 36px;
        }

        @include media-breakpoint-down(sm) {
          padding-left: 0;
          width: calc(100% - 32px);
        }
      }

      .cards {
        .carousel-arrow-left,
        .carousel-arrow-right {
          background-color: $viking-dark-gray;
          color: $viking-white;
          cursor: pointer;
          display: block;
          height: 44px;
          opacity: 0.45;
          position: absolute;
          top: 146px;
          width: 44px;
          z-index: 9;

          @include media-breakpoint-down(md) {
            height: 32px;
            top: 113.5px;
            width: 32px;
          }

          @include media-breakpoint-down(sm) {
            height: 32px;
            top: 138px;
            width: 32px;
          }

          .container-icon {
            font-size: 36px;

            @include media-breakpoint-down(md) {
              font-size: 24px;
            }
          }
        }

        .carousel-arrow-left {
          left: 16px;

          @include media-breakpoint-down(sm) {
            left: 24px;
          }
        }

        .carousel-arrow-right {
          right: 16px;

          @include media-breakpoint-down(sm) {
            right: 24px;
          }
        }

        .slick-slider {
          @include media-breakpoint-down(sm) {
            margin: 0 8px;
          }

          .slick-arrow {
            @include media-breakpoint-down(sm) {
              display: none !important;
            }
          }

          .slick-prev,
          .slick-next {
            display: none;
          }

          .slick-list {
            .slick-track {
              margin: 0;

              .slick-slide {
                padding: 0 6px;

                .cards-item {
                  align-items: center;
                  background: $viking-white;
                  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
                  display: flex !important;
                  flex-direction: row;

                  .cards-image {
                    flex: 0 0 157px;

                    @include media-breakpoint-down(lg) {
                      flex: 0 0 157px;
                    }

                    @include media-breakpoint-down(md) {
                      flex: 0 0 131px;
                    }
                  }

                  .cards-caption {
                    flex: auto;
                    padding: 0 18px 0 16px;

                    @include media-breakpoint-down(lg) {
                      padding: 0 18px 0 16px;
                    }

                    @include media-breakpoint-down(md) {
                      padding: 0 12px;
                    }
                  }

                  .caption-title {
                    color: $viking-black;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.33px;
                    margin-bottom: 4px;
                    text-transform: capitalize;

                    @include media-breakpoint-down(lg) {
                      font-size: 20px;
                      line-height: 24px;
                    }

                    @include media-breakpoint-down(md) {
                      font-size: 16px;
                      line-height: 18.646px;
                      letter-spacing: 0.256px;
                    }
                  }

                  .caption-description {
                    color: $viking-dark-gray;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    margin-bottom: 8px;

                    @include media-breakpoint-down(lg) {
                      font-size: 14px;
                      line-height: 18px;
                    }

                    @include media-breakpoint-down(md) {
                      font-size: 12px;
                      line-height: 14px;
                    }
                  }

                  .caption-button button {
                    font-size: 14px;
                    font-weight: 700;
                    height: auto;
                    line-height: 24px;
                    padding: 3px 15px;
                    text-transform: uppercase;

                    @include media-breakpoint-down(lg) {
                      font-size: 14px;
                      line-height: 24px;
                      padding: 3px 15px;
                    }

                    @include media-breakpoint-down(md) {
                      font-size: 12px;
                      line-height: 24px;
                      padding: 0 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
