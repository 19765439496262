@import '../../../css/variables';

.onboard-credit-card {
  .onboard-credit-card {
    border-bottom: $viking-light-gray-rule;
    padding-top: 32px;

    @include respond-to(false, sm) {
      padding: 16px 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    .btn-secondary-blue {
      width: 221px;

      @include media-breakpoint-down(sm) {
        width: 166px;
      }
    }

    .error-message {
      color: $viking-red;
    }
  }

  .alerts-banner {
    margin-bottom: 32px;
  }

  .form-credit-card {
    @include payment-form;

    .select-section .select__control {
      border-color: $viking-medium-black;
    }
  }

  .additional-actions {
    padding: 8px 0 24px;

    button {
      text-transform: uppercase;
    }

    @include respond-to(false, sm) {
      flex-direction: column;
      align-items: flex-start;

      .checkbox-container {
        padding-bottom: 8px;
      }
    }
  }

  .buttons {
    flex-direction: column;

    .payment-failed {
      color: $viking-red;

      @include body-copy-font(medium);

      padding-top: 8px;
      text-align: center;
    }
  }

  .has-saved-cc {
    .btn-secondary-blue {
      display: none;
    }
  }

  .disclaimer-message {
    @include media-breakpoint-down(sm) {
      font-size: $small-body-copy;
    }

    a {
      text-decoration: underline;
    }
  }
}
