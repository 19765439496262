@import '../../css/variables';

.notification-modal {
  .mvj-modal .content {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;

    .title {
      @include body-copy-font(large);

      font-weight: 700;

      @include respond-to(md, false) {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 8px;
      }
    }

    .notification-icon {
      font-size: 36px;
      margin-bottom: 17px;

      @include respond-to(md, false) {
        margin-bottom: 26px;
      }

      .error {
        color: $viking-red;
      }

      .success {
        color: $viking-dark-green;
      }
    }

    .message {
      max-width: 524px;

      @include body-copy-font(medium);

      text-align: center;
      padding: 0 10px;
      margin-bottom: 48px;

      @include media-breakpoint-down(md) {
        a {
          font-size: 13px;
        }
      }
    }

    .viking-button {
      width: 100%;

      @include body-copy-font(large);

      @include respond-to(md, false) {
        width: 234px;
      }
    }
  }
}
