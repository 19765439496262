@import '../../css/variables';

.confirm-modal {
  $default-button-margin: 32px;

  .text-content {
    margin: 0 auto;
    max-width: 458px;

    @include media-breakpoint-up(md) {
      max-width: 610px;
    }
  }

  .title {
    font-weight: 700;
    margin: 18px 0 16px;
    text-align: center;

    &.title-only {
      margin-bottom: 68px - $default-button-margin;
    }
  }

  .message {
    text-align: center;

    @include respond-to(false, md) {
      @include body-copy-font(small);
    }
  }

  .form-actions .buttons {
    margin: $default-button-margin 0 0;

    .btn {
      @include respond-to(false, md) {
        max-width: 288px;
        width: 100%;
        height: 40px;
        margin: 0 auto;
      }

      @include respond-to(md, false) {
        width: 254px;
        height: 50px;
      }
    }
  }

  .form-actions-wrapper .form-actions:not(.form-actions-stacked) .buttons {
    margin: 16px 0 0 0;

    @include media-breakpoint-down(sm) {
      margin: 4px 0 0 0;
    }

    .btn {
      width: 303px;

      @include media-breakpoint-down(sm) {
        width: 100%;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .passport-visa-dialog {
    margin-top: 32px;
    margin-bottom: -16px;

    button {
      margin-bottom: 8px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}
