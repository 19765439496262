@import '../../css/variables';

#ChallengeTimeoutModal {
  .new-modal-content {
    .message {
      text-align: center;
      padding-top: 48px;
      padding-bottom: 48px;
    }

    button.btn.btn-secondary-blue {
      margin: auto;
    }
  }
}
