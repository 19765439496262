.preference-page-wrapper {
  min-width: 300px;

  .loading-container {
    padding-top: 30px;
  }

  .form-actions .buttons {
    margin: 0;
  }
}
