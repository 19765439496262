@import '../../../../css/variables';

.food-allergies {
  .btn-cancel {
    @include respond-to(md, false) {
      margin-right: 21px;
    }

    @include respond-to(false, md) {
      margin-right: 16px;
    }
  }

  .passenger-name {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 24px;
  }

  .buttons {
    margin-top: 25px;
  }
}
